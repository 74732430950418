<template>

    <div id="app">
          <div class="container-fluid banner">
              <div class="d-flex justify-content-between">
                  <div>
                      <nav class="navbar navbar-dark fixed-left">
                          <div class="container-fluid">
                              <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas"
                                  data-bs-target="#offcanvasDarkNavbar" aria-controls="offcanvasDarkNavbar"
                                  aria-label="Toggle navigation">
                                  <span class="navbar-toggler-icon"></span>
                              </button>
                              <div class="offcanvas canvamenu offcanvas-start " tabindex="-1" id="offcanvasDarkNavbar"
                                  aria-labelledby="offcanvasDarkNavbarLabel">
                                  <div class="offcanvas-header">
                                      <button type="button" class="btn-close btn-close-dark" data-bs-dismiss="offcanvas"
                                          aria-label="Close"></button>
                                  </div>
                                  <div class="offcanvas-body">
                                      <ul class="nav nav-pills flex-column p-1 mt-3 text-start">
                                          <li class="nav-item"><span class="nav-link mx-2 text-reset">Function</span></li>
                                          <li class="nav-item">
                                              <router-link to="/withdrow" style="text-decoration: none;color: black;">
                                                  <div class="nav-link nav-link-custom m-2 mt-0" aria-current="" href="#">
                                                      <img src="../assets/menu_icon_account.e9b0e83b.svg" alt=""
                                                          style="width: 20px; height: auto; margin-right: 10px;">
                                                      <span class="text">My account</span>
                                                  </div>
                                              </router-link>
                                          </li>
                                          <li class="nav-item">
                                              <router-link to="/checkcard" style="text-decoration: none;color: black;">
                                                  <div class="nav-link nav-link-custom m-2 mt-0" aria-current="" href="#">
                                                      <img src="../assets/menu_icon_account.e9b0e83b.svg" alt=""
                                                          style="width: 20px; height: auto; margin-right: 10px;">
                                                      <span class="text">Check card</span>
                                                  </div>
                                              </router-link>
                                          </li>
                                          <li class="nav-item">
                                              <router-link to="/signin" style="text-decoration: none;color: black;" v-if="user==null">
                                                  <div class="nav-link nav-link-custom m-2 mt-0" aria-current=""><img
                                                          src="../assets/validate.ce708c38.svg" alt=""
                                                          style="width: 20px; height: auto; margin-right: 10px;">
                                                      <span class="text">Authentication</span>
                                                  </div>
                                              </router-link>
                                          </li>
                                          <li class="nav-item">
                                              <router-link to="/chat" style="text-decoration: none;color: black;">
                                                  <div class="nav-link nav-link-custom m-2 mt-0" aria-current="" href="#">
                                                      <img src="../assets/menu_icon_chat.05117d1f.svg" alt=""
                                                          style="width: 20px; height: auto; margin-right: 10px;">
                                                      <span class="text">Chat</span>
                                                  </div>
                                              </router-link>
                                              </li>
                                          <li class="nav-item">
                                              <router-link to="/faq" style="text-decoration: none;color: black;">
                                                  <div class="nav-link nav-link-custom m-2 mt-0" aria-current="" href="#">
                                                      <img src="../assets/menu_icon_qna.7ec34a68.svg" alt=""
                                                          style="width: 20px; height: auto; margin-right: 10px;">
                                                      <span class="text">Q&amp;A</span>
                                                  </div>
                                              </router-link>
                                          </li>
                                          <li v-if="user !=null" class="nav-item" v-on:click="log_out">
                                            <div class="nav-link nav-link-custom m-2 mt-0">
                                            <button  class="btn btn-primary" >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-right" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0z"></path> <path fill-rule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z"></path>
                                                </svg>
                                                Logout
                                            </button>
                                            </div>
                                           </li>
                                      </ul>
                                  </div>
                              </div>
                          </div>
                      </nav>
                  </div>
                  <div>
                      <router-link to="/signin" class="btn btn-primary bttn mt-2" v-if="user==null">Login Register</router-link>
                      <span class="bttn mt-2" v-else>UI11</span>
                  </div>
              </div>
              <div class="d-flex flex-column align-items-start">
                  <h1 class="text-white ms-2 mt-1">CaptalCoin</h1>
                  <h5 class="ms-2 h5" style="color: rgb(137, 168, 248);">Start making money plans</h5>
              </div>
              <!-- finnnnnn banner -->
          </div>
          <div class="container" v-if="user !== null"><br>
            <div class="row" v-if="sts==0">
                     <h5>
                        <marquee class="alert alert-danger">  Complete identity verification to keep your account active</marquee>
                        <router-link to="/checkcard" class="btn btn-outline-primary btn-sm">Check identity verification</router-link>
                    </h5>
            </div>
            <div class="row"  v-if="sts==-2">
                     <h5>
                        <marquee class="alert alert-warnig">Identity verification failed, please try again by submitting clear and visible data</marquee>
                        <router-link to="/checkcard" class="btn btn-outline-primary btn-sm">Try again verification </router-link>
                    </h5>
            </div>
          </div>
          <!--============================= Espace Pub============================-->
          <div class="container mt-3 d-flex justify-content-center " >
        
            <div id="carouselExample" class="carousel slide m-3" data-bs-ride="carousel">
    <div class="carousel-inner shadow-lg" style="background:#eee;  border-radius:20px;">
      <!-- First slide -->
      <div class="carousel-item active" >
        <div class="row d-flex align-items-center  ">
          <div class="col-7">
            <p class=" p-pub">
              <span class="fw-bold">Trade Now</span> <br> There will only ever be 21 million bitcoin mined. Get trading now
            </p>
          </div>
          <div class="col-5">
          <img src="https://static.rain.com/banners/trade_now.png" alt="banner" height="144px">
          <!--  -->
          </div>
        </div>
      </div>

      <!-- Second slide -->
      <div class="carousel-item">
        <div class="row d-flex align-items-center">
            <div class="col-7">
            <p class=" p-pub">
                <span class="fw-bold">Check out new assets</span> <br> Specially selected coins for you to trade including Shiba Inu, Chainlink, Tether, and more.
            </p>
          </div>
          <div class="col-5">
            <img src="https://static.rain.com/banners/new_assets.png" alt="banner" height="144px">
            <!--  -->
        </div>        
        </div>
      </div>
    </div>

    <!-- Carousel Controls -->
    <button
      class="carousel-control-prev"
      type="button"
      data-bs-target="#carouselExample"
      data-bs-slide="prev"
    >
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button
      class="carousel-control-next"
      type="button"
      data-bs-target="#carouselExample"
      data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
            
        </div>
         </div> 


          <!-- ===== Menu==== -->
          <div class="mt-3 d-flex">
              <div class="menu-items">
                  <router-link to="/withdrow" style="text-decoration: none;color: black;">
                      <div class="nav-link nav-link-custom m-2 mt-0">
                          <img src="../assets/menu_icon_account.e9b0e83b.svg" style="width: 30px; height: auto;">
                      </div>
                      <div class="text  text-center mt-auto mt-10 accounttxt">My account</div>
                  </router-link>
              </div>
              <div class="menu-items">
                  <router-link to="/withdrow" style="text-decoration: none;color: black;">
                      <div class="nav-link nav-link-custom m-2 mt-0">
                          <img src="../assets/tibi.ae56cb3e.svg" style="width: 30px; height: auto;">
                      </div>
                      <div class="text mt-10 withdrowtxt">Withdraw coins</div>
                  </router-link>
              </div>
              <div class="menu-items">
                  <router-link to="/history-records" style="text-decoration: none;color: black;">
                      <div class="nav-link nav-link-custom m-2 mt-0">
                          <img src="../assets/menu_icon_arbitrage.e895c6df.svg" style="width: 30px; height: auto;">
                      </div>
                      <div class="text mt-10 withdrowtxt">History</div>
                  </router-link>
              </div>
              <div class="menu-items">
                  <router-link to="/chat" style="text-decoration: none;color: black;">
                      <div class="nav-link nav-link-custom m-2 mt-0">
                          <img src="../assets/menu_icon_chat.05117d1f.svg" style="width: 30px; height: auto;">
                      </div>
                      <div class="text mt-10 accounttxt">Chat</div>
                  </router-link>
              </div>
          </div>
          <!-- 
            <div class="col-6 mb-2 mt-3"><small style="float:left">Switch between acccounts</small>
             <select v-model="bas" @change="changes" class="form-select form-select-lg mb-3" aria-label="Large select example">
                <option v-for="option in account_datas" :key="option.id_compte" :value="option.name_compte">
                    {{ option.name_compte }} : {{ option.balance }}
                </option>
             </select>
             
            </div>-->
  
          <div class="container-fluid mt-4 d-flex flex-column align-items-start">
              
              <div class="row mt-3">
                  <div class="col-12">
                      <div class="list-group d-inline-flex flex-row" role="tablist">
                          <div class="active list-group-item list-group-item-action listGroup-button rounded-pill rc"
                              id="list-top-list" data-bs-toggle="list" role="tab" aria-controls="list-top">Recommend</div>
                          <div class="list-group-item list-group-item-action listGroup-button rounded-pill mx-1"
                              id="list-star-list" data-bs-toggle="list" href="#list-star" role="tab"
                              aria-controls="list-star"><i class="fas fa-star"></i><span
                                  style="display: none;">Follow</span>
                          </div>
                          <div class="list-group-item list-group-item-action listGroup-button rounded-pill mx-1"
                              id="list-all-list" data-bs-toggle="list" role="tab" aria-controls="list-all">All products
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="flex flex-col mx-0 my-0 px-24 py-24 round-1">
              <div class="flex  flex-col flex-1">
                  <div class="coin-section m-t" v-for="crypto in cryptos" :key="crypto.id">
                      <div class="s-row little-line" v-on:click="trend(crypto)">
                          <!--  -->
                          <div class="col light">
                              <div class="d-flex">
                                  <!-- logo -->
                                  <div class="coinLogo">
  
                                      <img :src="'https://huobicfg.s3.amazonaws.com/currency_icon/' + crypto.symbol.toLowerCase()+ '.png'"
                                          style="height:23px;">
                                  </div>
                                  <!-- ==================== -->
  
                                  <!-- Nom crypto  -->
                                  <div class="ml-20">
                                      <div style="line-height: 0;">
                                          <div>
                                              <div class=""
                                                  style="line-height: 18px; color: rgb(51, 51, 51); font-size: 14px;">
                                                  <span>{{crypto.name}}</span>
                                              </div>
                                          </div>
                                      </div>
                                      <div style="line-height: 0;">
  
                                          <div user-select="false" class=""
                                              style="line-height: 15px; color: rgb(158, 158, 158); font-size: 12px;">
                                              <span>USDT</span>
                                          </div>
  
                                      </div>
                                  </div>
  
                                  <!-- ====================== -->
                                  
                              </div>
                          </div>
                          <!-- Prix Crypto-->
                          <div class="col r light">
                              <div style="line-height: 0;">
                                  <div style="line-height: 19px; color: rgb(255, 36, 20); font-size: 15px;">
                                      <span>{{p_fixed(crypto.priceUsd)}}</span>
                                  </div>
                              </div>
                          </div>
                          <!-- ================================ -->
  
                          <!-- pourcentage crypto -->
                          <div class="col r">
                              <div class="flex flex-row" style="float: right;">
                                  
                                  <button type="button" :class="this.ch_color(crypto.changePercent24Hr)">{{ch_fixed(crypto.changePercent24Hr)}}%</button>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </template>
  
  <script>
  import { ref, onMounted, onBeforeUnmount } from 'vue';
  import Swal from 'sweetalert2'
  export default {
    name:"RomeComponent",
    data() {
      return {
        cryptoData:[], // Déclarer cryptoData dans data()
        curr : ['bitcoin','ethereum','cardano','doge','ada'],
        bg_color : {
          "green": "btns btn-green",
          "red": "btns btn-red"
        },
        user:null,
        sts :null,
        intervall:0,
        bas:"",
        account_datas:""
      }
    },
    setup() {
      const cryptos = ref([]);
      const usdToUsdtRate = ref(1); // Taux de change USD/USDT initialisé à 1
      let socket;
      let changeUpdateInterval;
  
      const fetchUsdToUsdtRate = async () => {
        try {
          const response = await fetch('https://api.coincap.io/v2/rates/tether');
          const data = await response.json();
          usdToUsdtRate.value = 1 / data.data.rateUsd; // Conversion du taux de change
        } catch (error) {
          console.error('Erreur lors de la récupération du taux de change USD/USDT:', error);
        }
      };
  
      const fetchCryptoDetails = async () => {
        try {
             // api coinlayer -  https://coinlayer.com - avec socket io de coincap 
          const response = await fetch('https://api.coincap.io/v2/assets?ids=bitcoin,ethereum,cardano,dogecoin,solana,litecoin,xrp,troncoin,avalanche,polygon,filecoin,stellar,okb,maker,monero,fantom,theta,cosmos,algorand,flow,gala,aave,neo,ecash,decentraland,dexe '); //
          const data = await response.json();
          cryptos.value = data.data.map(crypto => ({
            id: crypto.id,
            name: crypto.name,
            symbol: crypto.symbol,
            priceUsd: crypto.priceUsd,
            priceUsdt: (crypto.priceUsd * usdToUsdtRate.value).toFixed(2),
            changePercent24Hr: crypto.changePercent24Hr
          }));
        } catch (error) {
          console.error('Erreur lors de la récupération des détails des cryptomonnaies:', error);
        }
      };

      const updateChangePercent = async () => {
      try {
        const response = await fetch('https://api.coincap.io/v2/assets?ids=bitcoin,ethereum,cardano'); // 
        const data = await response.json();
        data.data.forEach(crypto => {
          const existingCrypto = cryptos.value.find(c => c.id === crypto.id);
          if (existingCrypto) {
            existingCrypto.changePercent24Hr = parseFloat(crypto.changePercent24Hr).toFixed(2);
          }
        });
      } catch (error) {
        console.error('Erreur lors de la mise à jour du changement sur 24 heures:', error);
      }
    };
  
      const connectWebSocket = () => {
        socket = new WebSocket('wss://ws.coincap.io/prices?assets=bitcoin,ethereum,cardano,dogecoin,solana,litecoin,xrp,troncoin,avalanche,polygon,filecoin,stellar,okb,maker,monero,fantom,theta,cosmos,algorand,flow,gala,aave,neo,ecash,decentraland,dexe'); // Remplacez par les cryptomonnaies de votre choix
  
        socket.onmessage = (event) => {
          const data = JSON.parse(event.data);
          for (const [key, value] of Object.entries(data)) {
            const crypto = cryptos.value.find(c => c.id === key);
            if (crypto) {
              crypto.priceUsd = value;
              crypto.priceUsdt = (value * usdToUsdtRate.value).toFixed(2);
            }
          }
        };
  
        socket.onerror = (error) => {
          console.error('WebSocket error:', error);
        };
  
        socket.onclose = () => {
          //console.log('WebSocket connection closed, reconnecting...');
          setTimeout(connectWebSocket, 1000); // Reconnect after 1 second
        };
      };
  
      onMounted(async () => {
        await fetchUsdToUsdtRate();
        await fetchCryptoDetails();
        connectWebSocket();
        changeUpdateInterval = setInterval(updateChangePercent, 20000);
      });
  
      onBeforeUnmount(() => {
        if (socket) {
          socket.close();
        }
        clearInterval(changeUpdateInterval);
      });
  
      return { cryptos };
    },
    created(){
        this.user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null,
        this.sts = this.user?.sts ?? null
    },
    methods: {
        log_out(){
            let self = this;
            localStorage.clear();
           // this.$router.go(0);
           Swal.fire({
            title: "Good!",
            text: "Logout successfull",
            icon: "success"
           }).then(()=>{
            self.$router.push('/signin');
           });
            
        },
    /*changes(){
        let self = this;
            const id = parseInt(self.user.id);
            let form = new FormData();
            form.append("name_compte",this.bas);
            form.append("id_user",id);
             axios.post("https://server.captalcoin.com/user.php?reg=sendbal",form,{
                Headers:{
                    'Content-Type': 'Multipart/form-data'
                }
             })
                .then(response => {
                    if(response.data.success){
                        Swal.fire({
                        title: "Good job!",
                        text: response.data.message,
                        icon: "success"
                        });
                    }
                    else{
                        Swal.fire({
                        title: "Failed job!",
                        text: response.data.message,
                        icon: "warning"
                        });
                    }
                })
                .catch(err => {
                    console.log(err)
                })
    }, 
    async bascul(){
        let self = this;
            const id = parseInt(self.user.id);
            await axios.get("https://server.captalcoin.com/user.php?reg=bal&us=" + id)
                .then(response => {
                    self.account_datas = response.data.balance;
                    self.bas = response.data.bas;
                })
                .catch(err => {
                    console.log(err)
                })
    },*/
      trend(obj){
          localStorage.setItem("crypto_id", JSON.stringify(obj.name));
          localStorage.setItem("crypto_symbol", JSON.stringify(obj.symbol));
          const cuser = JSON.parse(localStorage.getItem("user"));
          if(! cuser){
              //axios.defaults.headers.common['Authorization'] = `Bearer ${user.u_token}`;
              return this.$router.push('/signin');
          }
          return this.$router.push('/tradingchart');
      },
      p_fixed(obj){
        return Number(obj.toString().match(/^-?\d+(?:\.\d{0,4})?/)[0]);
      },
      ch_fixed(obj){
        return Number(obj.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]);
      },
      ch_color(obj){
        if(parseFloat(obj)>=0){
          return this.bg_color.green;
        }
        else{
          return this.bg_color.red;
        }
      },
  }/*,

  mounted(){
    this.bascul()
  }*/

  }
  </script>
  
  
  <style scoped>
  #app {
      width: 100%;
      height: fit-content;
      min-height: 100vh;
      margin: 0 auto;
      display: block;
  }
  
  .banner {
      background: #1652f0;
      height: 190px;
      border-bottom-left-radius: 30px;
      border-bottom-right-radius: 30px;
      background-image: url("../assets/banner.png");
      background-position: right bottom;
      background-repeat: no-repeat;
      background-size: 30% auto;
  }
  
  .btns {
    display: inline-block;
    text-align: center;
    text-decoration: none;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    background-color: transparent;
    color: inherit;
    font-size: 1rem;
    line-height: 1.5;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.p-pub
{
    font-size: 15px;
}
  .bttn {
      font-size: 13px;
  }
  .btn-green {
    background-color: #28a745;  color: white;
}

.btn-red {
    background-color: #dc3545; color: white;
}
  @media only screen and (min-width: 320px) and (max-width: 360px) {
      .h5 {
          font-size: 1rem;
      }
  }
  
  .menu-items {
      text-align: center;
      width: 25%;
      display: block;
  }
  
  .accounttxt {
      font-size: 18px;
      
  }
  .withdrowtxt
  {font-size:17px;
  font-weight: 700;}
  
  .rc {
      padding-bottom: -8px;
  }
  
  .listGroup-button {
      padding: .375rem .75rem;
      margin-bottom: -1px;
      background-color: #fff;
      border: 1px solid rgba(0, 0, 0, .125);
      border-radius: .25rem;
      cursor: pointer;
      color: #495057;
      text-align: center;
      white-space: nowrap;
      vertical-align: middle;
      -webkit-user-select: none;
      -moz-user-select: none;
      user-select: none;
      font-size: 1rem;
      line-height: 1.5;
      transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  }
  
  .coin-section {
      padding: .125rem .9375rem .75rem;
      background: #fff;
  }
  
  .coin-section .s-row {
      display: flex;
      align-items: center;
      height: 3.75rem;
      border-bottom: 1px solid #eee;
  }
  
  .coin-section .s-row .col {
      flex: 1;
      font-weight: 700;
  }
  
  .coin-section .s-row .r {
      text-align: right;
  }
  
  .coin-section .s-row .coinLogo {
      width: 1.40625rem;
      height: 1.40625rem;
      margin-right: 8px;
  }
  
  .coinLogo {
      margin-left: 8px;
  }
  .dng{font-size:13px; font-weight:bold;}
  </style>
  