import HomeComponent from './components/HomeComponent.vue'
import SignUp from './components/SignUp.vue'
import SigninComponent from './components/SigninComponent.vue'
import TradingchartComponent from './components/TradingchartComponent.vue'
import OrderComponent from './components/OrderComponent.vue'
import ChatComponent from './components/ChatComponent.vue'
import WithdrowComponent from './components/WithdrowComponent.vue'
import ChartComponent from './components/ChartComponent.vue'
import ExchangeComponent from './components/ExchangeComponent.vue'

import ValidateComponent from './components/ValidateComponent.vue'
import IDComponent from './components/IDComponent.vue'

import RomeComponent from './components/RomeComponent.vue'
import EchangeComponent from './components/EchangeComponent.vue'
import NotificationComponent from './components/admin/NotificationComponent.vue'
import OperationComponent from './components/admin/OperationComponent.vue'
import HistoryComponent from './components/admin/HistoryComponent.vue'
import SigleOperation from './components/admin/SigleOperation.vue'
import CustomerComponent from './components/admin/CustomerComponent.vue'
import SigleCustomer from './components/admin/SigleCustomer.vue'
import VerifyComponent from './components/admin/VerifyComponent.vue'
import CheckConnection from './components/admin/CheckConnection.vue'
import FAQComponent from './components/FAQComponent.vue'
import AddaddressComponent from './components/AddaddressComponent.vue'
import RecordsComponent from './components/RecordsComponent.vue'
import SiglewdwComponent from './components/admin/SiglewdwComponent.vue'
import ValidationCard from './components/admin/ValidationCard.vue'

import {createRouter,createWebHistory} from 'vue-router'

const routes=[
    {
        name:'HomeComponent',
        component:HomeComponent,
        path:'/'
    },
    {
        name:'SignUp',
        component:SignUp,
        path:'/signup'
    },
    {
        name:'SigninComponent',
        component:SigninComponent,
        path:'/signin'
    },
    {
        name:'TradingchartComponent',
        component:TradingchartComponent,
        path:'/tradingchart'
    },
    {
        name:'OrderComponent',
        component:OrderComponent,
        path:'/order'
    },
    {
        name:'ChatComponent',
        component:ChatComponent,
        path:'/chat'
    },
    {
        name:'WithdrowComponent',
        component:WithdrowComponent,
        path:'/withdrow'
    },
    {
        name:'ChartComponent',
        component:ChartComponent,
        path:'/charts'
    },
    {
        name:'ExchangeComponent',
        component:ExchangeComponent,
        path:'/exchange'
    },
    {
        name:'ValidateComponent',
        component:ValidateComponent,
        path:'/validation/:tok'
    },
    {
        name:'IDComponent',
        component:IDComponent,
        path:'/checkcard'
    },
    {
        name:'RomeComponent',
        component:RomeComponent,
        path:'/rhome'
    }
    ,
    {
        name:'EchangeComponent',
        component:EchangeComponent,
        path:'/change'
    },
    {
        name:'FAQComponent.vue',
        component:FAQComponent,
        path:'/faq'
    } ,
    {
        name:'AddaddressComponent.vue',
        component:AddaddressComponent,
        path:'/add-bind'
    } ,
    {
        name:'RecordsComponent.vue',
        component:RecordsComponent,
        path:'/history-records'
    },
    {
        name:'SiglewdwComponent.vue',
        component:SiglewdwComponent,
        path:'/admin/sigle-wdw'
    },
    {
        name:'CheckConnection.vue',
        component:CheckConnection,
        path:'/aodm/log'
    },
    {
        name:'NotificationComponent.vue',
        component:NotificationComponent,
        path:'/admin/notification',
        meta: { requiresAuth: true }
    },
    {
        name:'OperationComponent.vue',
        component:OperationComponent,
        path:'/admin/operations',
        meta: { requiresAuth: true }
    },
    {
        name:'SigleOperation.vue',
        component:SigleOperation,
        path:'/admin/sigleoperation',
        meta: { requiresAuth: true }
    },
    {
        name:'HistoryComponent.vue',
        component:HistoryComponent,
        path:'/admin/history',
        meta: { requiresAuth: true }
    },
    {
        name:'CustomerComponent.vue',
        component:CustomerComponent,
        path:'/admin/customers',
        meta: { requiresAuth: true }
    },
    {
        name:'SigleCustomer.vue',
        component:SigleCustomer,
        path:'/admin/siglecustomer',
        meta: { requiresAuth: true }
    },
    {
        name:'VerifyComponent.vue',
        component:VerifyComponent,
        path:'/admin/check',
        meta: { requiresAuth: true }
    },
    {
        name:'ValidationCard',
        component:ValidationCard,
        path:'/admin/validationcard/:id',
        meta: { requiresAuth: true }
    }

];

const router = createRouter({
    history:createWebHistory(),
    routes
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
      const authKey = JSON.parse(localStorage.getItem('aodm'));
      if (!authKey) {
        next({ name: 'Login' });
      } else {
        const lastThreeDigits = authKey.slice(-3);
        const only_key = process.env.VUE_APP_AODMI
        if (lastThreeDigits === only_key) { 
          next();
        } else {
          next({ name: 'HomeComponent' });
        }
      }
    } else {
      next();
    }
  });

export default router