<template>
    <div id="app">
        <div class="container-fluid banner">
            <div class="d-flex justify-content-between">
                <div>
                    <nav class="navbar navbar-dark fixed-left">
                        <div class="container-fluid">
                            <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas"
                                data-bs-target="#offcanvasDarkNavbar" aria-controls="offcanvasDarkNavbar"
                                aria-label="Toggle navigation">
                                <span class="navbar-toggler-icon"></span>
                            </button>
                            <div class="offcanvas canvamenu offcanvas-start " tabindex="-1" id="offcanvasDarkNavbar"
                                aria-labelledby="offcanvasDarkNavbarLabel">
                                <div class="offcanvas-header">
                                    <button type="button" class="btn-close btn-close-white" data-bs-dismiss="offcanvas"
                                        aria-label="Close"></button>
                                </div>
                                <div class="offcanvas-body">
                                    <ul class="nav nav-pills flex-column p-1 mt-3 text-start">
                                        <li class="nav-item"><span class="nav-link mx-2 text-reset">Function</span></li>
                                        <li class="nav-item">
                                            <router-link to="/admin/customers" class="nav-link nav-link-custom m-2 mt-0"
                                                aria-current="" href="#">
                                                <img src="../../assets/validate.ce708c38.svg" alt=""
                                                    style="width: 20px; height: auto; margin-right: 10px;">
                                                <span class="text">Customer List</span>
                                            </router-link>
                                        </li>
                                        <li class="nav-item">
                                            <router-link to="/admin/operations"
                                                class="nav-link nav-link-custom m-2 mt-0" aria-current="" href="#"><img
                                                    src="../../assets/menu_icon_setup.81a4b22b.svg" alt=""
                                                    style="width: 20px; height: auto; margin-right: 10px;">
                                                <span class="text">Operation</span>
                                            </router-link>
                                        </li>
                                        <li class="nav-item">
                                            <router-link to="/admin/history" class="nav-link nav-link-custom m-2 mt-0"
                                                aria-current="" href="#"><img
                                                    src="../../assets/menu_icon_share.2e020ec7.svg" alt=""
                                                    style="width: 20px; height: auto; margin-right: 10px;">
                                                <span class="text">History</span>
                                            </router-link>
                                        </li>
                                        <li class="nav-item">
                                            <router-link to="/admin/notification"
                                                class="nav-link nav-link-custom m-2 mt-0" aria-current="" href="#">
                                                <img src="../../assets/menu_icon_chat.05117d1f.svg" alt=""
                                                    style="width: 20px; height: auto; margin-right: 10px;">
                                                <span class="text">Notification</span>
                                            </router-link>
                                        </li>
                                        <li class="nav-item" v-on:click="log_out">
                                            <div class="nav-link nav-link-custom m-2 mt-0">
                                                <button class="btn btn-primary">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                        fill="currentColor" class="bi bi-box-arrow-right"
                                                        viewBox="0 0 16 16">
                                                        <path fill-rule="evenodd"
                                                            d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0z">
                                                        </path>
                                                        <path fill-rule="evenodd"
                                                            d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z">
                                                        </path>
                                                    </svg>
                                                    Logout
                                                </button>
                                            </div>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>

            </div>
            <div class="d-flex flex-column align-items-start">
                <h1 class="text-white ms-2 mt-1">CaptalCoin | Administration</h1>
                <h5 class="ms-2 h5" style="color: rgb(137, 168, 248);">Start making money plans</h5>
            </div>
            <!-- finnnnnn banner -->
        </div>
        <!-- ===== Menu==== -->
        <div class="mt-3 d-flex" id="pills-tab" role="tablist">

            <router-link to="/admin/customers" class="menu-items">
                <div class="nav-link nav-link-custom m-2 mt-0 ">
                    <img src="../../assets/validate.ce708c38.svg" alt=""
                        style="width: 30px; height: auto; margin-right: 10px;">
                </div>
                <div class="text  text-center mt-auto mt-10 accounttxt">Customer List</div>
            </router-link>
            <router-link to="/admin/operations" class="menu-items nav-link-custom m-2 mt-2">
                <div class="nav-link nav-link-custom m-2 mt-0">
                    <img src="../../assets/menu_icon_setup.81a4b22b.svg" alt=""
                        style="width: 30px; height: auto; margin-right: 10px;">
                    <div class="text mt-10 accounttxt">Operation</div>
                </div>
            </router-link>
            <router-link to="/admin/history" class="menu-items">
                <div class="nav-link nav-link-custom m-2 mt-0">
                    <img src="../../assets/menu_icon_share.2e020ec7.svg" alt=""
                        style="width: 30px; height: auto; margin-right: 10px;">
                </div>
                <div class="text mt-10 accounttxt">History</div>
            </router-link>
            <router-link to="/admin/notification" class="menu-items">
                <div class="nav-link nav-link-custom m-2 mt-0">
                    <img src="../../assets/menu_icon_chat.05117d1f.svg" style="width: 30px; height: auto;">
                </div>
                <div class="text mt-10 accounttxt d-flex">Notification</div>
            </router-link>
        </div>
        <div class="row col-sm-8 offset-sm-2">
            <h4>Identity Verification</h4>
            <div class="form-group">
                <label for="">Choose Issues</label>
                <select v-model="handle" name="" id="" class="form-control">
                    <option value="" selected hidden>choosing..</option>
                    <option value="1">Accept</option>
                    <option value="2">Declined</option>
                </select>
            </div>

            <div class="form-group"><br>
                <button v-on:click="save_change" class="btn btn-outline-primary btn-block">Save</button>
            </div>
        </div><br>
        <!-- =====End Menu==== -->
        <h4>Picture <button class="btn btn-outline-primary btn-sm" v-on:click="down(imageUrl, user.pcard)">download</button></h4>
        <div class="container-fluid mt-4 d-flex flex-column align-items-start" v-if="user !== null">
            <img :src="'https://server.captalcoin.com/pieces/' + user.pcard" alt="picture of piece" class="col-md-8 offset-md-2" target="_blank">
        </div>

    </div>
</template>


<script>
import axios from 'axios';
import swal from 'sweetalert';
export default {
    name: 'ValidationCard',
    data() {
        return {
            data: '',
            sigle_op: null,
            user: null,
            iduser: null,
            handle: "",
            imageUrl: ""
        }
    },
    created() {
        if (this.$route.params.id) {
            this.iduser = this.$route.params.id;
            
        } else {
            this.$router.push('/admin/customers');
        }
        this.getuser()
    },
    methods: {
        showAlert(title, msg, icon, showConfirmButton = true) {
            return swal({
                title: title,
                text: msg,
                icon: icon,
                button: showConfirmButton

            });
        },
        down(url, nomFichier) {
            const lien = document.createElement('a');
            lien.href = url;
            lien.download = nomFichier;

            document.body.appendChild(lien);
            lien.click();

            document.body.removeChild(lien);
        },
        async getuser() {
            let self = this;
            let id_user = this.iduser;
            console.log(id_user)
            await axios.post("https://server.captalcoin.com/adm.php?op=getus&us=" + id_user)
                .then(response => {
                    console.log(response)
                    if (response.data.success) {
                        self.user = response.data.user
                        self.imageUrl = 'https://server.captalcoin.com/pieces/' + self.user.pcard
                    }
                    else {
                        self.showAlert("error", response.data.message, "warning", false)
                    }
                })

        },
        log_out() {
            let self = this;
            localStorage.clear();
            // this.$router.go(0);
            swal({
                title: "Good!",
                text: "Logout successfull",
                icon: "success"
            }).then(() => {
                self.$router.push('/aodm/log');
            });

        },
        async save_change() {
            let form = new FormData();
            form.append("id", this.iduser);
            form.append("handle", this.handle);
            let self = this;
            await axios.post("https://server.captalcoin.com/adm.php?op=majcard", form, {
                headers: {
                    'Content-Type': 'Multipart/form-data'
                }
            })
                .then(response => {
                    if (response.data.success) {
                        swal({
                            title: "Good!",
                            text: "successfull",
                            icon: "success"
                        }).then(() => {
                            self.$router.push('/admin/customers');
                        });
                    }
                    else {
                        swal("error", response.data.message, "warning");
                    }
                })
                .catch(err => {
                    console.error(err)
                })

        }

    }

}

</script>

<style scoped>
#app {
    width: 100%;
    height: fit-content;
    min-height: 100vh;
    margin: 0 auto;
    display: block;
}

.banner {
    background: #1652f0;
    height: 190px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    background-image: url("../../assets/banner.png");
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: 30% auto;
}

.bttn {
    font-size: 13px;
}

@media only screen and (min-width: 320px) and (max-width: 360px) {
    .h5 {
        font-size: 1rem;
    }
}

.menu-items {
    text-align: center;
    width: 25%;
    display: block;
}

.accounttxt {
    font-size: 18px;
    font-weight: 700;
}

.withdrowtxt {
    font-size: 17px;
    font-weight: 700;
}

.rc {
    padding-bottom: -8px;
}

.listGroup-button {
    padding: .375rem .75rem;
    margin-bottom: -1px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: .25rem;
    cursor: pointer;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    font-size: 1rem;
    line-height: 1.5;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.coin-section {
    padding: .125rem .9375rem .75rem;
    background: #fff;
}

.coin-section .s-row {
    display: flex;
    align-items: center;
    height: 3.75rem;
    border-bottom: 1px solid #eee;
}

.coin-section .s-row .col {
    flex: 1;
    font-weight: 700;
}

.coin-section .s-row .r {
    text-align: right;
}

.coin-section .s-row .coinLogo {
    width: 1.40625rem;
    height: 1.40625rem;
    margin-right: 8px;
}

.coinLogo {
    margin-left: 8px;
}

.dng {
    background-color: rgb(255, 36, 20);
    font-size: 13px;
    font-weight: bold;
}

.canvamenu {
    max-width: 70%;
}
</style>