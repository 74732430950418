<template>
    <div id="app">
        <div class="flex flex-col flex-1">
            <div class="d-flex align-items-center justify-content-between my-3">
                <router-link to="/withdrow" class="ms-2">
                     <i class="fas fa-arrow-left p-2"></i>

                </router-link>

                <div class="text-center">
                    <h6>CryptoCurrency</h6>
                </div>

                 <router-link to="/history-records" class="me-4">
                    <div class="text-decoration-none text-reset">
                        <i class="fa-solid fa-clipboard-list"></i>
                    </div>
                 </router-link> 
            </div>
            <h1 class="text-center" >US$ {{wal.balance}}</h1>
            <div class="d-flex justify-content-center" >
                <img :src="'https://huobicfg.s3.amazonaws.com/currency_icon/'+ wal.name_compte.toLowerCase() +'.png'" alt=""  style="max-height: 20px; width: auto;">
                <p class="m-0 p-0 ms-2" >{{wal.balance}} {{wal.name_compte}}</p>
            </div>
            
        </div>
        <div class="ma-20 container">
            <div class="row">
            <ul class="nav nav-pills mb-3 justify-content-between" id="pills-tab" role="tablist">
        <li class="nav-item " role="presentation"> <!-- Ajout de mx-2 pour l'espacement -->
          <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Receive</button>
        </li>
        <li class="nav-item " role="presentation"> 
          <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Send</button>
          
        </li>
        <li class="nav-item " role="presentation"> <!-- Ajout de mx-2 pour l'espacement -->
          <button class="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Exchange</button>
        </li>
      </ul>
            </div>
            <div class="shadow-sm">
                <div class="tab-content" id="pills-tabContent">
                    <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">

                      <div class="card mb-4">
                        <div class="card-body">
                          <!-- <h5 class="fw-semibold mb-3 text-capitalize">Future Trade Form</h5> -->
                          <form action="#">
                            
                            <div class="mb-3" v-if="wal.name_compte=='USDT'">
                              <p class="form-label fw-bold text-start">Receiving assets</p>
                              <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                <li class="nav-item" role="presentation">
                                  <button class="nav-link active" id="pills-erc-tab" data-bs-toggle="pill" data-bs-target="#pills-erc" type="button" role="tab" aria-controls="pills-erc" aria-selected="true">ERC-20 {{wal.name_compte}}</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                  <button class="nav-link" id="pills-trc-tab" data-bs-toggle="pill" data-bs-target="#pills-trc" type="button" role="tab" aria-controls="pills-trc" aria-selected="false">TRC-20</button>
                                </li>

                              </ul>
                              <div class="tab-content" id="pills-tabContent">
                                <div  class="tab-pane fade show active " id="pills-erc" role="tabpanel" aria-labelledby="pills-erc-tab">

                                  <div class=" d-flex justify-content-center">
                                    <qrcode-vue :value="wal.adresse"></qrcode-vue>,
                                    
                                </div>
                                  <p class="text-center mt-2" id="copybutton1" readonly>{{wal.adresse}}</p>
                                  <p class="mt-2 text-center text-primary cursor-pointer" @click="jscopyA()">Copy adress </p>

                                </div>
                                <div class="tab-pane fade" id="pills-trc" role="tabpanel" aria-labelledby="pills-trc-tab">

                                  <div class="d-flex  justify-content-center"  >
                                    <qrcode-vue :value="wal.adresse"></qrcode-vue>
                                  </div>
                                  <p class="text-center mt-2" id="copybutton2" readonly>{{wal.adresse}}</p>
                                  <p class="mt-2 text-center text-primary cursor-pointer" @click="jscopyB()">Copy adress </p>

                                </div>
                              </div>
                            </div>
                            <div class="mb-3"  v-if="wal.name_compte=='BTC'">
                              <p class="form-label fw-bold text-start">Receiving assets</p>
                             
                              <div class="tab-content" id="pills-tabContent">
                                <div class="tab-pane fade show active " id="pills-erc" role="tabpanel" aria-labelledby="pills-erc-tab">

                                  <div class=" d-flex justify-content-center">
                                    <qrcode-vue :value="wal.adresse"></qrcode-vue>
                                  </div>
                                  <p class="text-center mt-2" id="copybutton2" readonly>{{wal.adresse}}</p>
                                  <p class="mt-2 text-center text-primary cursor-pointer" @click="jscopyB()">Copy adress </p>

                                </div>
                                <div class="tab-pane fade" id="pills-trc" role="tabpanel" aria-labelledby="pills-trc-tab">

                                  <div class="d-flex  justify-content-center"  >
                                    <qrcode-vue :value="wal.adresse"></qrcode-vue>
                                  </div>

                                </div>
                              </div>
                            </div>
                            <div class="mb-3">
                              <p class="form-label fw-bold text-start">Deposit Mount</p>
                              <div class="input-group mb-3 rounded" style="background-color:#f5f5f5">
                                    <span class="input-group-text"><img :src="'https://huobicfg.s3.amazonaws.com/currency_icon/'+ wal.name_compte.toLowerCase() +'.png'" class="img-fluid mr-20" alt="" style="max-height: 20px;"></span>
                                    <input type="number" v-model="deposit.amount" class="form-control" aria-label="Amount (to the nearest dollar)" style="background-color:#f5f5f5">
                                    <span class="input-group-text">{{wal.name_compte}}</span>
                                </div>
                            </div>
                            <div class="mb-3">
                              <p class="form-label fw-bold text-start">Deposit Screenshot</p>
                              
                              <div class="file-picker__box border" style="height:80px; width: 31%;">
                                    <input type="file" ref="fileInput" accept="image/*" style="display: none" @change="handleFileChange">
                                    <div v-if="!selectedImage" class="file-picker__box-content is-add mt-4" style="border-width: 1px; border-style: solid; border-color: rgb(238, 238, 238); border-radius: 3px;" @click="openFileExplorer">
                                    <div class="is-add">
                                        <div class="icon-add"></div>
                                        <div class="icon-add rotate"></div>
                                    </div>
                                    </div>
                                    <img v-if="selectedImage" :src="selectedImage" alt="Selected Image" style=" width: 100%; height: auto; max-height: 100%; max-width: 100%;">
                                    
                            </div>
                        </div>
                            
                          </form>
                          <button v-on:click="receive"  class="btn btn-success btn-block col-10" >Submit </button>
                        </div>
                    </div>
                        
                    </div>
                     <!-- ======================== -->
                     <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
        <div class="card mb-4">
          <div class="card-body">

            <div action="#">

              <div class="mb-3">
                <p class="form-label fw-bold text-start">sending assets</p>
                <select v-model="send.adress" class="form-select form-control" aria-label="Default select example">
                  <option v-for="option in get_address" :key="option.id_sender" :value="option.adress">{{option.adress}}</option>
                </select>
                <span style="float:left"><router-link to="/add-bind">Go to bind<i class="fas fa-add"></i></router-link></span>
              </div>
              <label class="form-label "></label>
              <div class="input-group mb-3">
                <div class="input-group mb-3 rounded" style="background-color:#f5f5f5">
                            <span class="input-group-text"><img :src="'https://huobicfg.s3.amazonaws.com/currency_icon/'+ wal.name_compte.toLowerCase() +'.png'" class="img-fluid mr-20" alt="" style="max-height: 20px;"></span>
                            <input type="number" v-model="send.amount" class="form-control" aria-label="Amount (to the nearest dollar)" style="background-color:#f5f5f5">
                            <span class="input-group-text">{{wal.name_compte}}</span>
                         </div>
              </div>
              <div class="mb-3">
                <p class="form-label fw-bold text-start">Fund Password</p>
                <textarea v-model="send.fun_pw" cols="30" rows="4" class="form-control" placeholder=""></textarea>
              </div>
              <div class="row">
                <div class="col">
                  <button v-on:click="sender" class="btn btn-primary w-100 text-capitalize mb-1">Send</button>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      
                     <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
        <div class="col-xxl-4  mb-xxl-0 d2c_margin_trade">
          <div class="card bg-white bg-opacity-10">
            <div class="card-body">
              <p class="fw-semibold mb-2 text-start">Exchange</p>
              <div action="#" class="d2c_trade_form">
                <div class="mb-3">
                  <p class="form-label text-start">From</p>
                  <div class="input-group mb-3 rounded" style="background-color:#f5f5f5">
                                    <span class="input-group-text"><img src="https://huobicfg.s3.amazonaws.com/currency_icon/btc.png" class="img-fluid mr-20" alt="" style="max-height: 20px; margin-right:3px;" > BTC</span>
                                    <input type="number" v-model="btcAmount" @input="convertToUsdt" class="form-control" aria-label="Amount (to the nearest dollar)" style="background-color:#f5f5f5">
                                    <span class="input-group-text">MAX</span>
                                </div>
                </div>

                <div class="mb-3">
                  <p class="form-label text-start">arrive</p>
                  <div class="input-group">
                    <input type="number" v-model="usdtAmount" @input="convertTobtc" class="form-control bg-white bg-opacity-25" >
                    <span class="input-group-text p-0 border-0 bg-transparent">
                      <select v-model="exchange.to" class="form-select rounded-0 text-secondary fw-semibold rounded-end bg-white form-control" aria-label="Default select example">
                        <option selected="selected" value="USDT">USDT</option><!--
                        <option value="1">BTC</option>
                        <option value="2">USD</option>
                        <option value="3">GBP</option>
                        <option value="4">EUR</option>-->

                      </select>
                    </span>
                  </div>
                </div>
                <button v-on:click="exchange" class="btn btn-primary w-100 text-white fw-semibold text-capitalize"> Exchange</button>
              </div>
            </div>
          </div>
        </div>

      </div> 
                </div>

            </div>
         </div>
        </div>
</template>


<script>
import axios from 'axios';
import QrcodeVue from 'qrcode.vue'
import swal from 'sweetalert';
export default {
  name: 'ExchangeComponent',
  components: {
    QrcodeVue,
  },
  data() {
    return {
      selectedImage: null,
      value1: '0xa8d6f0BbCC1dc3aEBdD143d9B72992b245d72308',
      value2: 'TUyfrRJch6j9vH6ivSwj69B4SHVEduJwqo',
      user:"",
      wal:"",
      get_address:[],
      deposit:{
        "amount":0,
        "picture":"",
        "id_user":null,
        "wal":""
      },
      send:{
        "amount":0,
        "adress":"",
        "id_user":null,
        "fun_pw":"",
        "wal":""
      },
      usdtAmount: 0,
      btcAmount: 0,
      usdtTobtcRate: 0,
      btcToUsdtRate: 0
    };
  },
  created(){
      this.user = JSON.parse(localStorage.getItem('user')) ?? this.$router.push('/signin')
      this.wal = JSON.parse(localStorage.getItem('wal')) ?? this.$router.push('/withdrow')
  },
  methods: {
    async sender_adrr(){
      let self = this;
      const id_user = this.user.id;
      const name_adresse = this.wal.name_compte;
      await axios.get("https://server.captalcoin.com/user.php?reg=getadd&us="+id_user+"&adresse="+name_adresse)
      .then(response=>{
        if(response.data.success){
          
          self.get_address = response.data.adresses
        }
      })
      .catch(err=>{
        console.error(err)
      })
    },
    showAlert(title, msg, icon, showConfirmButton = true) {
            return swal({
                title: title,
                text: msg,
                icon: icon,
                button: showConfirmButton
            });
    },
     async receive(){
        let self = this;
        if(this.deposit.amount==0 || this.deposit.picture=="" || this.user.id ==null){
          swal("ERROR","Required all field","warning")
        }
        else{
          let form = new FormData();
          form.append("amount",this.deposit.amount);
          form.append("picture",this.deposit.picture);
          form.append("id_user",this.user.id);
          form.append("wal",this.wal.name_compte);
          // console.log(this.deposit.picture)
          await axios.post("https://server.captalcoin.com/operation.php?op=receive",form,{
            Headers:{
              'Content-Type':'Multipart/form-data'
            }
          }).then(function(response){
            if(response.data.success){
              swal("Success",response.data.message,"success").then(function(){
                self.$router.push('/')
              });
            }
            else{
              swal("Error",response.data.message,"warning");
            }
          })
          .catch(err=>{
            console.error(err)
          })
        }
    },
    async sender(){
        let self = this;
        if(this.send.amount==0 || this.send.adress=="" || this.user.id==null || this.send.fun_pw ==""){
          swal("ERROR","required all field","warning")
        }
        else{
          let form = new FormData();
          form.append("amount",this.send.amount);
          form.append("adress",this.send.adress);
          form.append("id_user",this.user.id);
          form.append("wal",this.wal.name_compte);
          form.append("fun_pw",this.send.fun_pw);
          //console.log(this.user.id)
          await axios.post("https://server.captalcoin.com/operation.php?op=send",form,{
            Headers:{
              'Content-Type':'Multipart/form-data'
            }
          }).then(function(response){
            if(response.data.success){
              swal("Success",response.data.message,"success").then(function(){
                self.$router.push('/')
              });
            }
            else{
              swal("Error",response.data.message,"warning");
            }
          })
          .catch(err=>{
            console.error(err)
          })
        }
    },
    
    async exchange(){
        let self = this;
        if(this.btcAmount==0 || this.usdtAmount=="" || this.user.id == null){
          swal('Error',"Required all field","warning")
        }
        else{
          let form = new FormData();
          form.append("btcamount",this.btcAmount);
          form.append("usdtamount",this.usdtAmount);
          form.append("id_user",this.user.id);
          //console.log(this.user.id)
          await axios.post("https://server.captalcoin.com/operation.php?op=exchange",form,{
            Headers:{
              'Content-Type':'Multipart/form-data'
            }
          }).then(function(response){
            if(response.data.success){
              swal("Success",response.data.message,"success").then(function(){
                self.$router.push('/')
              });
            }
            else{
              swal("Error",response.data.message,"warning");
            }
          })
          .catch(err=>{
            console.error(err)
          })
        }
    },
    openFileExplorer() {
      this.$refs.fileInput.click();
    },
    handleFileChange(event) {
      this.deposit.picture = event.target.files[0]
      //console.log(this.deposit.picture)
      const file = event.target.files[0];
      if (file && file.type.startsWith('image/')) {
        const reader = new FileReader();
        reader.onload = () => {
          this.selectedImage = reader.result;
        };
        reader.readAsDataURL(file);
      } else {
        alert('Veuillez sélectionner un fichier image.');
      }
    },
    jscopyA() {
      var txt = document.getElementById("copybutton1").innerText;
      navigator.clipboard.writeText(txt)
        .then(() => alert("Copied"));
    },
    jscopyB() {
      var txt2 = document.getElementById("copybutton2").innerText;
      navigator.clipboard.writeText(txt2)
        .then(() => alert("Copied"));
    },
    
    async fetchRates() {
        try {
          const usdtResponse = await axios.get('https://api.coincap.io/v2/rates/tether');
          const btcResponse = await axios.get('https://api.coincap.io/v2/rates/bitcoin');
          const usdtRateUsd = parseFloat(usdtResponse.data.data.rateUsd);
          const btcRateUsd = parseFloat(btcResponse.data.data.rateUsd);
          this.usdtTobtcRate = usdtRateUsd / btcRateUsd;
          this.btcToUsdtRate = btcRateUsd / usdtRateUsd;
        } catch (error) {
          console.error('Error fetching rates:', error);
        }
      },
      convertTobtc() {
        this.btcAmount = (this.usdtAmount * this.usdtTobtcRate).toFixed(6);
      },
      convertToUsdt() {
        this.usdtAmount = (this.btcAmount * this.btcToUsdtRate).toFixed(2);
      }
  },
  mounted() {
  //   var qrc = new QRCode(document.getElementById("qrcode"), "0xa8d6f0BbCC1dc3aEBdD143d9B72992b245d72308");
  //   var qrc2 = new QRCode(document.getElementById("qrcode2"), "TUyfrRJch6j9vH6ivSwj69B4SHVEduJwqo");
  this.fetchRates();
  this.sender_adrr();
   }
}
</script>


<style scoped>
#app {
    width: 100%;
    height: fit-content;
    min-height: 100vh;
    margin: 0 auto;
    display: block;
}

.sub_title{
    font-size: 14px;
    color: #000;
    font-weight: 700;
 }

 .is-add {
    display: flex;
    align-items: center;
    justify-content: center;
}
 .rotate {
    position: absolute;
    transform: rotate(90deg);
}
 .icon-add {
    width: 50px;
    height: 5px;
    background-color: #f1f1f1;
    border-radius: 2px;
}
.mtt
{
    margin-top: 2.4rem !important;
}
.modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
}

.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 90%;
  max-height: 90%;
}

.close {
  color: #fff;
  position: absolute;
  top: 10px;
  right: 25px;
  font-size: 35px;
  font-weight: bold;
  cursor: pointer;
}
</style>

