<template>

    

    <div id="app">
        <div class="container-fluid bg-white shadow-sm rounded text-black text-center p-2"> My entrusted Order</div> 
        <div class="container">
            <div class="row">
                <div class="col-2"></div> 
        <ul class="nav nav-pills mb-3 col-8 bg-white p-2 d-flex justify-content-between px-3" id="pills-tab" role="tablist">
          <li class="nav-item" role="presentation">
            <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Waiting</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Finished</button>
          </li>
        </ul>
        <div class="tab-content " id="pills-tabContent">
          <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
            <div class="col-xxl-4 mb-4" v-for="row in data" :key="row.id">

              <div class="card border  mb-3 bg-card bg-opacity-10" v-if="row.statut == 'wait'">
                <div class="card-header d-flex justify-content-between align-items-center border-bottom ">
                  <div>{{row.pair_crypto}}</div>
                  <div>{{row.created_at}}</div>
                </div>
                <div class="card-body text-primary">
                  <div class="d-flex justify-content-between">
                    <div class="text-start">
                      <p>Hosting Amount</p>
                      <p>{{row.hosting_amount}}</p>
                      <p>Buy Price</p>
                      <p>Closing Price</p>
                      <p>Handing Fee</p>
                      <p>Profit</p>
                    </div>
                    <div>
                      <h6>Direction</h6>
                      <p :class=" row.direction=='Fall' ? 'text-dangr' : 'text-success' ">{{row.direction == "Up" ? row.direction : 'Rise'}}</p>
                      
                    </div>
                    <div class="text-end">
                      <p>Investment Time</p>
                      <p>{{row.investment_time}}</p>
                      <p>{{row.buy_price}}</p>
                      <p>63497.5951</p>
                      <p class="text-dangr">-</p>
                      <p class="text-succes">----</p>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" v-for="rs in data" :key="rs.id">

            <div class="col-xxl-4 mb-4" v-if="rs.statut=='close'">
              <div class="card border  mb-3 bg-card bg-opacity-10">
                <div class="card-header d-flex justify-content-between align-items-center border-bottom ">
                  <div>{{rs.pair_crypto}}</div>
                  <div>{{rs.created_at}}</div>
                </div>
                <div class="card-body text-primary">
                  <div class="d-flex justify-content-between">
                    <div class="text-start">
                      <p>Hosting Amount</p>
                      <p>{{rs.hosting_amount}}</p>
                      <p>Buy Price</p>
                      <p>Closing Price</p>
                      <p>Handing Fee</p>
                      <p>Profit</p>
                    </div>
                    <div>
                      <h6>Direction</h6>
                      <p :class=" rs.direction=='Fall' ? 'text-dangr' : 'text-success' ">{{rs.direction == "Up" ? rs.direction : 'Rise'}}</p>
                    </div>
                    <div class="text-end">
                      <p>Investment Time</p>
                      <p>{{rs.investment_time}}</p>
                      <p>{{rs.buy_price}}</p>
                      <p>63497.5951</p>
                      <p class="text-dangr">-</p>
                      <p :class="rs.profit>0 ? 'text-succes' : 'text-danger'">{{rs.profit}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
   
                <div class="col-2"></div>
            </div>
        </div>
    </div>
</template>


<script>
import axios from 'axios';
export default {
    name: 'OrderComponent',
    data(){
      return {
        data:'',
        user:""
      }
    },
    created(){
      this.user = JSON.parse(localStorage.getItem('user')) ?? this.$router.push('/signin')
    },
    methods:{
      async order(){
        let self = this;
        //let form_data = new FormData();
       // form_data.append("user_id",this.user.id)
       await axios.get("https://server.captalcoin.com/server.php?list_currency=orders&user_id="+this.user.id)
        .then(response=>{
          self.data = response.data.orders;
          //console.log(self.data)
        })
        .catch(err=>{
          console.error(err)
        })
      }
      
    },
    mounted(){
      this.order()
      setInterval(this.order, 50000);
    }

}

</script>

<style>
#app {
    width: 100%;
    height: fit-content;
    min-height: 100vh;
    margin: 0 auto;
    display: block;
    background-color: #f5f5f5;
}
.bg-card
{
    background-color:#fafafa;
}
.text-succes
{
    
    color:#4ec752;
}
.text-dangr
{
    color:#ff2414;
}
.card,p,h6
{
    color:black;
}
</style>
