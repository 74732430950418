<template>
  <div id="app">
    <div class="container-fluid">
      <div class="shadow-sm rounded d-flex justify-content-between p-3" style="background: rgb(245, 245, 245);">
        <router-link to="/">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-left"
            viewBox="0 0 16 16">
            <path fill-rule="evenodd"
              d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0" />
          </svg>
        </router-link>
        <div>Validation</div>
        <div></div>
      </div>
      <div class="container-fluid">

        <div class="row justify-content-center">

          <div class="col-4">
            <h2>{{msge}}</h2>
          </div>

        </div>

      </div>
    </div>
  </div>
</template>


<script>
import axios from "axios"
import swal from 'sweetalert';
export default {
  name: 'ValidateComponent',
  data() {
    return {
      tok: null,
      msge: "Veuillez patientez..."
    }
  },
  methods: {
    showAlert(title, msg, icon, showConfirmButton = true) {
      return swal({
        title: title,
        text: msg,
        icon: icon,
        button: showConfirmButton,
        timer: 2000,
      });
    },
    async checktok() {
      try {

        let self = this;
        if (this.tok !== "") {
          var form = new FormData();
          form.append('tok', this.tok)
          self.showAlert("Info", "Waiting...", "warning", false)
          await axios.post("https://server.captalcoin.com/user.php?reg=valid",form,{
            Headers: {
              'Content-Type': 'Multipart/form-data'
            }
          })
            .then(function (response) {

              if (response.data.success == true) {
                //console.log(response)
                self.msge = "Validation successfully.You can login now !!!"
                setTimeout(() => {
                  self.showAlert("success", "Validation successfully.You can login now !!!", "success", false)
                  self.$router.push('/signin')
                }, 5000);

              }
              else {
                self.msge = response.data.message+"...";
                self.showAlert("Error", response.data.message, "warning")
              }
            })
        }
        else{
          self.showAlert("warning", "Operation Failed", "warning", false)
        }

      } catch (error) {
        console.error('Erreur de connexion :', error);
      }
    }
  },
  created() {
    this.tok = this.$route.params.tok;

    this.checktok()
  }
}

</script>

<style scoped>
#app {
  width: 100%;
  height: fit-content;
  min-height: 100vh;
  margin: 0 auto;
  display: block;
}
</style>