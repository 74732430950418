<template> 
    <div class="container">
        <div class="flex flex-col flex-1">
            <div class="d-flex align-items-center justify-content-between my-3">
                <router-link to="/" class="ms-2">
                     <i class="fas fa-arrow-left p-2"></i>

                </router-link>

                <div class="text-center">
                    <h6>CryptoCurrency</h6>
                </div>

                 <div class="me-4">
                    <router-link to="/order" class="text-decoration-none text-reset">
                        <i class="fa-solid fa-clipboard-list"></i>
                    </router-link>
                 </div> 
            </div>
            <h1 class="text-center" >US$ {{sigle_cr.priceUsd}}</h1>
            <div class="d-flex justify-content-center" >
                <img src="https://huobicfg.s3.amazonaws.com/currency_icon/usdt.png" alt=""  style="max-height: 20px; width: auto;">
                <p class="m-0 p-0 ms-2" >{{sigle_cr.priceUsd}} USDT</p>
            </div>
            
        </div>
        <ChartComponent/>
          <button  class="btn btn-primary btn-block col-8" data-bs-toggle="modal" data-bs-target="#monModal">Order </button>
          <div class="modal modal-bottom " id="monModal" tabindex="-1">
              <div class="modal-dialog modal-dialog-centered modal-fullscreen rnd">
                  <div class="modal-content">
                      <div class="modal-header">
                          <h5 class="modal-title fw-bold">{{sigle_cr.symbol}}/USDT Delivery</h5>
                          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Fermer"></button>
                      </div>
                      <div class="modal-body">
                          <div class="d-flex align-items-center justify-content-between my-1">
                                  <div class="d-flex align-items-center">
                                      <img :src="'https://huobicfg.s3.amazonaws.com/currency_icon/' + symbole + '.png'" alt=""  style="height: 30px;">
                                      <div class="ms-2" >
                                          <h6 class="m-0 p-0 mb-1 fw-bold" >{{sigle_cr.name}}</h6><h6 class="m-0 p-0 fw-bold" >Buy <span :class=" td1.maj + ' fw-bold'" >{{td1.name}}</span></h6>
                                      </div>
                                  </div>
                                  <div class="text-end" >
                                          <div class="d-flex align-items-center mb-1" >
                                              <img src="../assets/icon_time.20dd9c20.svg" alt=""  style="height: 15px;">
                                              <h6 class="m-0 p-0 ms-2 fw-bold" >{{this.tk_timz()}}</h6>
                                          </div> 
                                          <h6 class="m-0 p-0 fw-bold" >{{amount}} USDT</h6>
                                      </div>
                          </div>
                          <p class="m-0 p-0 text-start">Delivery time</p>
                          <div class="row align-items-center my-1 mb-3">
                              <div class="col-5">
                              <div class="d-flex align-items-center justify-content-center p-1 py-2 border rounded" >
                                  <img src="../assets/icon_time.20dd9c20.svg" alt=""  style="height: 15px;">
                                      <select class="form-select" v-model ="fram" aria-label="Default select example">
                                          <option value="1" selected="selected">60S</option>
                                          <option value="2">120S</option> 
                                          <option value="3">300S</option>
                                          <option value="4">10M</option>
                                      </select>
                                  
              
                                  </div>                      
                              </div>
                              <div class="col-7 text-end" >
                                  <div class="d-flex ms-3" >
                                      <div class="custom-button leftBtn text-start" id="sl_up" v-on:click="ch_up" >Up</div>
                                      <div class="custom-button rightBtn" id="sl_fall" v-on:click="ch_fall" >Fall</div>
                                  </div>
                              </div>
                          </div>
                          <p class="m-0 p-0 text-start" >Price range</p>
                          <div class="d-flex align-items-center justify-content-center p-1 px-4 py-2 border rounded" >
                              <img id="img1" src="../assets/icon_up_green.19f36634.svg" alt=""  style="height: 20px;">
                              <img id="img2" src="../assets/red_down.873c8120.svg" alt=""  style="height: 20px; display:none">
                              <p :class="'m-0 p-0 ms-2 ' + td1.maj " >{{td2.name}}</p>
                              <i class="dropdown-toggle ms-auto" ></i>
                          </div>
                          <p class="m-0 p-0 text-start" >Purchase Amount</p>
                              
                          <div class="align-items-center mb-1" >
                              <div   class="flex flex-col mx-0 my-0 px-0 py-0 round-0"   style="border: 0px solid rgb(230, 230, 230); background-color: rgba(255, 255, 255, 0);">
                                  <div  class="flex  flex-col flex-1" >
                                      <div   class="flex flex-col mx-0 my-0 px-24 py-0 round-4"  style="border: 1px solid rgb(230, 230, 230); background-color: rgb(245, 245, 245);"><div  class="flex  flex-col flex-1" >
                                          <div class="flex flex-row flex-row-center-center" style="height: 40px;">
                                              <div class="input-group">
                                                  <span class="input-group-text"><img src="https://huobicfg.s3.amazonaws.com/currency_icon/usdt.png" class="img-fluid mr-20" alt=""  style="max-height: 20px;"></span>
                                                  <input type="number" v-model="amount" aria-label="First name" class="form-control" style="background-color: rgb(245, 245, 245);"> 
                                                  <span class="input-group-text">Minimum : {{ this.tk_frame() }}</span>
                                                  
                                          </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              </div>
                              <div class="flex flex-row-center-between" >
                                  <div class="Balance text-start mt-1" >Available assets:{{account_data}}</div>
                              </div>
                              <button v-on:click="Orderplace"  :class=" ord + ' col-10 mt-2'" data-bs-toggle="" data-bs-target="">Order </button>
                          </div>
                      </div>    
  
                  </div>
                  
              </div>
          </div>
      </div>
    
  </template>
  <script>
  import axios from 'axios'
  import ChartComponent from './ChartComponent.vue';
  import swal from 'sweetalert';
  import Swal from 'sweetalert2'
  export default {
    name: 'TradingchartComponent',
    components:{
        ChartComponent
    },
    data(){
      return {
          td1:{
            maj : "text-success",
            name : "Up"
          },
          ord: "btn btn-success",
          td2:{
            name: "Up"
          },
          account_data:null,
          frwme:100,
          fram : 1,
          amount : 0,
          timz:"0S",
          sigle_cr:"",
          current_cr:"",
          data:"",
          user:"",
          symbole:"",
          err_amount : "222",
      }
  
    },
    created(){
      this.user = JSON.parse(localStorage.getItem('user')) ?? this.$router.push('/signin')
    },
    methods: {
        p_fixed(obj){
            return Number(obj.toString().match(/^-?\d+(?:\.\d{0,4})?/)[0]);
        },

        showAler() {
           let ord = ` 
                <div class="container">
                    <div class="row"> 
                        <h5 class="col-6"><b>Direction</b></h5>
                        <h5 class="col-6"><b>UP</b></h5> 
                    </div>
                    <div class="row"> 
                        <h5 class="col-6"><b>Direction</b></h5>
                        <h5 class="col-6"><b>UP</b></h5> 
                    </div>
                    <div class="row"> 
                        <h5 class="col-6"><b>Direction</b></h5>
                        <h5 class="col-6"><b>UP</b></h5> 
                    </div>
                </div>
                `
            this.$swal.fire({
                        title: "Success",
                        html: ord,
                        timer: 60000,
                        timerProgressBar: true,
                         didOpen: () => {
                            Swal.showLoading();
                        }
                        })

    },
        
        showAlert(title,msg,icon) {
            return swal(title,msg,icon);
        },
      tk_frame(){
          if(this.fram==1){
              this.frwme = 100
          }
          else if(this.fram==2){
              this.frwme = 10000
          }
          else if(this.fram==3){
              this.frwme = 50000
          }
          else{
              this.frwme = 100000
          }
  
          return this.frwme;
      },
      tk_timz(){
          if(this.fram==1){
              this.timz = "60S"
          }
          else if(this.fram==2){
              this.timz = "120S"
          }
          else if(this.fram==3){
              this.timz = "300S"
          }
          else{
              this.timz = "10M"
          }
  
          return this.timz;
      },
      ch_up(){
          this.td1.maj = "text-success";
          this.td1.name = "Up";
          this.td2.name = "Up";
          this.ord = "btn btn-success"
      },
      ch_fall(){
          this.td1.maj = "text-danger";
          this.td1.name = "Fall";
          this.td2.name = "Fall";
          this.ord = "btn btn-danger" 
      },
      d_chang(){
        var up = document.getElementById('sl_up');
        var fall = document.getElementById('sl_fall');
        up.addEventListener("click", function() {
          up.style.backgroundColor = "#28a745";
          fall.style.backgroundColor = "#999";
          document.getElementById("img1").style.display = "inline"
          document.getElementById("img2").style.display = "none"
        });
  
        fall.addEventListener("click", function() {
            up.style.backgroundColor = "#999";
            fall.style.backgroundColor = "red";
            document.getElementById("img1").style.display = "none"
            document.getElementById("img2").style.display = "inline"
        });
  
      },
      ls(){
        this.current_cr = JSON.parse(localStorage.getItem('crypto_id'));
      },
      fetch_only() {
          var self = this;
          let crypto = JSON.parse(localStorage.getItem('crypto_id'));
          crypto = crypto.toLocaleLowerCase();
          const u_token = this.user.u_token;
          // api coinlayer -  https://coinlayer.com - avec socket io de coincap 
          //const only_key = process.env.VUE_APP_ONLY_KEY 
          axios.get('https://api.coincap.io/v2/assets/'+crypto,{
              headers:{
                  'Authorization':`Bearer ${u_token}`
              }
          }) 
          .then(response => {
          self.sigle_cr = response.data.data; 
          self.symbole = self.sigle_cr.symbol.toLocaleLowerCase();
          //console.log(self.sigle_cr)
          })
          .catch(error => {
          console.error('Error :', error);
          });
      },
      async Orderplace(){
         /* let data = {
              "direction" : this.td1.name,
              "investment_time":this.tk_timz(),
              "hosting_amount":this.amount,
              "min_amount":this.tk_frame(),
              "pair_crypto":this.sigle_cr.symbol+"/USDT",
              "buy_price" : this.sigle_cr.priceUsd,
              "user_id":this.user.id
          }*/

          let check = this.check();
          let self = this;
          if(check){
                //console.log(data)
                let neworder = this.addAll();
            try{
                await axios.post('https://server.captalcoin.com/server.php?list_currency=only_currency',neworder,{
                    headers:{
                        'Content-Type' : 'Multipart/form-data'
                    }
                })
                .then(function(response){
                    //console.log(response)
                    if(response.data.success){
                        let ord = "<span style='color:green'>Order placed successfully.</span> Please Wait for "+self.tk_timz()
                        let dirs = self.td1.name =='Up' ? `<h5 class="col-6"><span style="float:right;color:green"><b>${self.td1.name}</b></span></h5>` : `<h5 class="col-6"><span style="float:right;color:red"><b>${self.td1.name}</b></span></h5>`
                        let ords = ` 
                                <div class="container">
                                    <h5>${ord}</h4>
                                    <div class="row"> 
                                        <h5 class="col-6"><span style="float:left"><b>Direction</b></span></h5>
                                        ${dirs}
                                    </div>
                                    <div class="row"> 
                                        <h5 class="col-6"><span style="float:left"><b>Hosting Amount</b></span></h5>
                                        <h5 class="col-6"><span style="float:right"><b>${self.amount}</b></span></h5> 
                                    </div>
                                    <div class="row"> 
                                        <h5 class="col-6"><span style="float:left"><b>Buy price</b></span></h5>
                                        <h5 class="col-6"><span style="float:right"><b>${self.p_fixed(self.sigle_cr.priceUsd)}</b></span></h5> 
                                    </div>
                                </div>
                                `
                        /* swal({
                            title: "Success",
                            text: ord,
                            content: {
                                element: "img",
                                attributes: {
                                src: "../assets/pev.gif",
                                alt: "Loading"
                                },
                            },
                            button: false,
                            closeOnClickOutside: false,
                            });
                        */
                        let time = 0;
                        if(self.tk_timz()== "60S"){
                            time =65000;
                        }
                        else if(self.tk_timz()=="120S"){
                            time = 125000;
                        }
                        else if(self.tk_timz()=="300S"){
                            time = 305000;
                        }
                        else{
                            time = 605000;
                        }

                       // let timerInterval;
                        self.$swal.fire({
                        title: '<span style="color: #28a745">Success !!!</span>',
                        html: ords,
                        timer: time,
                        timerProgressBar: true,
                        showConfirmButton: false,
                        confirmButtonColor: '#28a745',
                        allowOutsideClick: false,
                         didOpen: () => {
                            const progressBar = document.querySelector('.swal2-timer-progress-bar');
                            if (progressBar) {
                                progressBar.style.backgroundColor = 'green';
                            }

                            Swal.showLoading();
                        }
                        }).then((result) => {
                            if (result.dismiss === Swal.DismissReason.timer) {
                                self.responseord();
                            }
                        });
                        
                      /*  setTimeout(() => {
                            self.responseord();
                        }, time);

                     self.showAlert("Success",ord,"success").then(function(){
                            self.$router.push("/");
                        });*/
                    }
                    else{
                        self.showAlert("Failed",response.data.message,"warning")
                    }
                    
                })
                    
            }
            catch(error) {
                    console.error("error :",error)
            } 
          }
          
      },
      async responseord(){
        let self = this;
        let form = new FormData();
        form.append("id",this.user.id);
        await axios.post('https://server.captalcoin.com/server.php?list_currency=ords',form,{
                    headers:{
                        'Content-Type' : 'Multipart/form-data'
                    }
                })
                .then(function(response){
                   // console.log(response)
                    if(response.data.success){
                        self.account();
                        let ord = response.data.profit > 0  ? '<h5 style="color:green">'+'+'+response.data.profit+'</h4>'  : '<h5 style="color:red">'+response.data.profit+'</h4>'
                            let dirs = self.td1.name =='Up' ? `<h5 class="col-6"><span style="float:right;color:green"><b>${self.td1.name}</b></span></h5>` : `<h5 class="col-6"><span style="float:right;color:red"><b>${self.td1.name}</b></span></h5>`
                        let ords = ` 
                                <div class="container">
                                    ${ord}
                                    <div class="row"> 
                                        <h5 class="col-6"><span style="float:left"><b>Direction</b></span></h5>
                                        ${dirs} 
                                    </div>
                                    <div class="row"> 
                                        <h5 class="col-6"><span style="float:left"><b>Hosting Amount</b></span></h5>
                                        <h5 class="col-6"><span style="float:right"><b>${self.amount}</b></span></h5> 
                                    </div>
                                    <div class="row"> 
                                        <h5 class="col-6"><span style="float:left"><b>Buy price</b></span></h5>
                                        <h5 class="col-6"><span style="float:right"><b>${self.p_fixed(self.sigle_cr.priceUsd)}</b></span></h5> 
                                    </div>
                                </div>
                                `

                        self.$swal.fire({
                        title: response.data.message,
                        html: ords,
                        icon: response.data.result,
                        confirmButtonColor: '#28a745'
                        }).then(() => {
                            self.$router.go(0);
                        });



                       /* swal({
                            title: "Finish",
                            text: response.data.message,
                            icon: response.data.result
                        }).then(function(){
                            self.$router.push('/order');
                        })*/
                    }
                    else{
                       // self.showAlert("Faild",response.data.message,"warning")
                      // console.log(response.data.message)
                      swal({
                            title: "Failed",
                            text: response.data.message,
                            icon: "warning"
                        })
                    }
                    
                })
      },
      check(){
          let min = this.tk_frame();
          if(this.amount < min || this.amount==0){
             // alert("Pls enter a valid amount")
              //this.err_amount = "Pls enter a valid amount"
              //swal("Pls enter a valid amount")
              this.showAlert("Error","Please enter a valid amount","warning")
              return false
          }
          else if (this.amount > this.user.balance){
              this.showAlert("Error","Amount unvaliable","warning")
              return false
          }
          else{
              return true
          }
      },
      addAll: function () {
          var form_data = new FormData();
          form_data.append('direction', this.td1.name);
          form_data.append('investment_time',this.tk_timz())
          form_data.append('hosting_amount',this.amount)
          form_data.append('min_amount',this.tk_frame())
          form_data.append('pair_crypto',this.sigle_cr.symbol+"/USDT")
          form_data.append('buy_price',this.sigle_cr.priceUsd)
          form_data.append('user_id',JSON.parse(this.user.id))
  
          return form_data;
      },
      async account() {
            let self = this;
            const id = parseInt(self.user.id);
            await axios.get("https://server.captalcoin.com/user.php?reg=ac_bal&us=" + id)
                .then(response => {
                    self.account_data = response.data.balance.balance;
                })
                .catch(err => {
                    console.log(err)
                })
        }
  
    },
    mounted() {
      this.account();
      this.ls();
      this.d_chang();
      this.tk_frame();
      this.tk_timz();
      this.fetch_only();
      setInterval(this.fetch_only, 3000); 


    }
  }
  </script>
  
  
  
  <style scoped>
  .swal2-timer-progress-bar {
    background-color: blue !important;
}
  .modal-fullscreen{
      padding-top:10%;
  }
  @media (min-width: 315px) and (max-width: 700px) {
      .modal-fullscreen{
      padding-top:50%;
  }
  
  
  }
  
  
  .leftBtn {
      padding: 10px 30px;
      background-color: #28a745;
      color: #fff;
      width: 100%;
      border: none;
      cursor: pointer;
      display: inline-block;
      margin-right: -2px;
      -webkit-clip-path: polygon(0 0, 100% 0, 79% 100%, 0% 100%);
      clip-path: polygon(0 0, 100% 0, 79% 100%, 0% 100%);
  }
  
  .rightBtn {
      width: 100%;
      padding: 10px 30px;
      background-color: #999;
      color: #fff;
      border: none;
      cursor: pointer;
      display: inline-block;
      margin-left: -2px;
      -webkit-clip-path: polygon(16% 1%, 100% 0, 100% 100%, 0% 100%);
      clip-path: polygon(16% 1%, 100% 0, 100% 100%, 0% 100%);
  }
  .fwb
  {
      font-weight:bold;
  }
  
  .uni-input-wrapper {
      display: flex;
      position: relative;
      width: 100%;
      height: 100%;
      flex-direction: column;
      justify-content: center;
  }
  
  .uni-input-placeholder {
      position: absolute;
      top: auto !important;
      left: 0;
      color: gray;
      overflow: hidden;
      text-overflow: clip;
      white-space: pre;
      word-break: keep-all;
      pointer-events: none;
      line-height: inherit;
  }
  .uni-input-placeholder, .uni-input-input {
      width: 100%;
  }
  .uni-input-input {
      position: relative;
      display: block;
      height: 100%;
      background: none;
      color: inherit;
      opacity: 1;
      font: inherit;
      line-height: inherit;
      letter-spacing: inherit;
      text-align: inherit;
      text-indent: inherit;
      text-transform: inherit;
      text-shadow: inherit;
  }
  
  .Balance {
      color: #5b616e;
      font-size: 12px;
      font-weight: bolder;
      margin-right: 10px;
  }
  
  </style>
  