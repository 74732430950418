<template>
  <div>
    <h1>Prix des Cryptomonnaies en Temps Réel (en USDT)</h1>
    <ul>
      <li v-for="crypto in cryptos" :key="crypto.id">
        {{ crypto.name }} ({{ crypto.symbol }}): {{ crypto.priceUsdt }} USDT
        <br>
        Changement sur 24h: {{ crypto.changePercent24Hr }}%
      </li>
    </ul>
  </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from 'vue';

export default {
  name:'RomeComponent',
  setup() {
    const cryptos = ref([]);
    const usdToUsdtRate = ref(1); // Taux de change USD/USDT initialisé à 1
    let socket;

    const fetchUsdToUsdtRate = async () => {
      try {
        const response = await fetch('https://api.coincap.io/v2/rates/tether');
        const data = await response.json();
        usdToUsdtRate.value = 1 / data.data.rateUsd; // Conversion du taux de change
      } catch (error) {
        console.error('Erreur lors de la récupération du taux de change USD/USDT:', error);
      }
    };

    const fetchCryptoDetails = async () => {
      try {
        const response = await fetch('https://api.coincap.io/v2/assets?ids=bitcoin,ethereum,cardano'); // Remplacez par les cryptomonnaies de votre choix
        const data = await response.json();
        cryptos.value = data.data.map(crypto => ({
          id: crypto.id,
          name: crypto.name,
          symbol: crypto.symbol,
          priceUsd: crypto.priceUsd,
          priceUsdt: (crypto.priceUsd * usdToUsdtRate.value).toFixed(2),
          changePercent24Hr: crypto.changePercent24Hr
        }));
      } catch (error) {
        console.error('Erreur lors de la récupération des détails des cryptomonnaies:', error);
      }
    };

    const connectWebSocket = () => {
      socket = new WebSocket('wss://ws.coincap.io/prices?assets=bitcoin,ethereum,cardano'); // Remplacez par les cryptomonnaies de votre choix

      socket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        for (const [key, value] of Object.entries(data)) {
          const crypto = cryptos.value.find(c => c.id === key);
          if (crypto) {
            crypto.priceUsd = value;
            crypto.priceUsdt = (value * usdToUsdtRate.value).toFixed(2);
          }
        }
      };

      socket.onerror = (error) => {
        console.error('WebSocket error:', error);
      };

      socket.onclose = () => {
        console.log('WebSocket connection closed, reconnecting...');
        setTimeout(connectWebSocket, 1000); // Reconnect after 1 second
      };
    };

    onMounted(async () => {
      await fetchUsdToUsdtRate();
      await fetchCryptoDetails();
      connectWebSocket();
    });

    onBeforeUnmount(() => {
      if (socket) {
        socket.close();
      }
    });

    return { cryptos };
  }
};
</script>

<style scoped>
ul {
  list-style-type: none;
  padding: 0;
}
li {
  margin: 5px 0;
}
</style>
