<template>
  <div id="app">
    <div class="container-fluid">
      <div class="shadow-sm rounded d-flex justify-content-between p-3">
        <router-link to="/exchange">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-left"
            viewBox="0 0 16 16">
            <path fill-rule="evenodd"
              d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0" />
          </svg>
        </router-link>
        <div>Add Payement...</div>
        <div></div>
      </div>
      <div class="container">
        <div class="bg-white p-3 rounded shadow-lg mt-2">
          <p class="text-start">Binding type</p>
          <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
            <li class="nav-item" role="presentation">
              <button class="nav-link active" id="pills-digi-tab" data-bs-toggle="pill" data-bs-target="#pills-digi"
                type="button" role="tab" aria-controls="pills-digi" aria-selected="true">Digital currency</button>
            </li>
          </ul>
          <div class="tab-content mb-3" id="pills-tabContent">
            <div class="mb-3">
                <p class="form-label fw-bold text-start">sending assets</p>
                <select v-model="sender.name_adresse" class="form-select form-control" aria-label="Default select example">
                  <option value="USDT">USDT</option>
                  <option value="BTC">BTC</option>
                </select>
              </div>
              <div class="mb-3">
                <p class="form-label fw-bold text-start">Address</p>
                <textarea v-model="sender.adresse" cols="30" rows="4" class="form-control" placeholder=""></textarea>
              </div>
          </div>
        <button type="button" v-on:click="send" class="btn btn-primary col-10 rounded-pill">Save Address</button>

        </div>
      </div>
    </div>
  </div>
</template>


<script>
import axios from 'axios';
import swal from 'sweetalert';
export default {
  name: 'AddaddressComponent',
  data(){
    return{
      user:null,
      sender :{
        name_adresse : "",
        adresse : "",
        id_user : ""
      }
    }
  },
  created(){
      this.user = JSON.parse(localStorage.getItem('user')) ?? this.$router.push('/signin')
  },
  methods:{
    async send(){
      let self = this;
      if(this.sender.name == "" || this.sender.adresse == "" || this.user == null){
        swal('Error','All field required','warning')
      }
      else{
        let form = new FormData();
        form.append("name_adresse",this.sender.name_adresse);
        form.append("adresse",this.sender.adresse);
        form.append("id_user",this.user.id);
        await axios.post("https://server.captalcoin.com/user.php?reg=add_adr",form,{
          headers: {
            'Content-Type': 'Multipart/form-data'
          }
        })
        .then(response=>{
          if(response.data.success){
            swal("Good job!",response.data.message,"success");
            self.$router.go(0)
          }
          else{
            swal("Error",response.data.message,"warning");            
          }
        })

      }
    }
  }
}

</script>

<style>
#app {
  width: 100%;
  height: fit-content;
  min-height: 100vh;
  margin: 0 auto;
  display: block;
  background: rgb(245, 245, 245);
}
</style>
