<template>
    <div id="app">
        <div class="container-fluid">
            <div class="shadow-sm rounded d-flex justify-content-between p-3">
                <router-link to="/admin/notification" >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-left" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"></path></svg>
                </router-link>
                <div></div>
            </div>
            <div class="container mt-2">
                <div class="bg-white p-3 rounded shadow-lg cursor-pointer"> 

                    <form >
                        <div class="mb-3 mt-3">
                            <p  class=" text-start">User {{ data.id_user }}</p>
                        </div>
                        <div class="mb-3">
                            <p class=" text-start">Amount: {{ data.amount }}</p>
                            <input type="montant" class="form-control" id="montant" :value="data.amount" >
                        </div>
                        <div class="mb-3">
                            <p class=" text-start">Photo: </p>
                            <div  class="form-control" id="montant" style="height:150px; width:40%;"><img :src="'https://server.captalcoin.com/'+data.picture" alt=""></div>
                        </div>
                        
                        <button type="submit" class="btn btn-primary">Submit</button>
                    </form>

                </div>
            </div>
        </div>
    </div>
</template>


<script>
import axios from 'axios';
import swal from 'sweetalert';
export default {
    name: 'VerifyComponent',
    data() {
        return {
            data: '',
            sigle_op: null,
            trade: {
                'id': null,
                'option': ''
            },
            deposit:null
        }
    },
    created() {
        this.deposit = JSON.parse(localStorage.getItem('deposit')) ?? this.$router.push('/admin/notification');
    },
    methods: {
        async save_change() {
            let form = new FormData();
            form.append("id", this.user);
            form.append("option", this.trade.option);
            console.log(this.trade)
            await axios.post("https://server.captalcoin.com/operation.php?op=c_hange", form, {
                headers: {
                    'Content-Type': 'Multipart/form-data'
                }
            })
                .then(response => {
                    if (response.data.success) {
                        swal("Success", response.data.message, "success");
                    }
                    else {
                        swal("Warning", response.data.message, "warning");
                    }
                })
                .catch(err => {
                    console.error(err)
                })

        },
        async operation() {
            let self = this;
            //let form_data = new FormData();
            // form_data.append("user_id",this.user.id)
            const id = parseInt(this.deposit);
            await axios.get("https://server.captalcoin.com/adm.php?op=depot&curr_depot=" + id)
                .then(response => {
                    self.data = response.data.deposit;
                    console.log(self.data)
                })
                .catch(err => {
                    console.log(err)
                })
        }

    },
    mounted() {
        this.operation()
    }
}

</script>

<style scoped >
#app {
    width: 100%;
    height: fit-content;
    min-height: 100vh;
    margin: 0 auto;
    display: block;
    background: rgb(245, 245, 245);
}
</style>
