<template>
    <div id="app">
        <div class="container-fluid">
            <div class="shadow-sm rounded d-flex justify-content-between p-3">
                <router-link to="/" >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-left" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"/></svg>
                </router-link>
                 <div>chat</div>
                <div></div>
            </div>
            <div class="container">
                <a  href="https://t.me/captalcoin_247" target="_blank"> <div class="bg-white p-3 rounded shadow-lg cursor-pointer"> Chat </div></a>
            </div>
        </div>
    </div>
</template>


<script>

export default {
    name: 'HomeComponent'
}

</script>

<style>
#app {
    width: 100%;
    height: fit-content;
    min-height: 100vh;
    margin: 0 auto;
    display: block;
    background: rgb(245, 245, 245);
}
</style>
