<template>
    <div>
      <h1>Crypto Exchange</h1>
      <div>
        <label for="usdt">USDT:</label>
        <input type="number" v-model="usdtAmount" @input="convertTobtc">
      </div>
      <div>
        <label for="btc">btc:</label>
        <input type="number" v-model="btcAmount" @input="convertToUsdt">
      </div>
      <p>1 USDT = {{ usdtTobtcRate }} BTC</p>
      <p>1 BTC = {{ btcToUsdtRate }} USDT</p>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    name:"EchangeComponent",
    data() {
      return {
        usdtAmount: 0,
        btcAmount: 0,
        usdtTobtcRate: 0,
        btcToUsdtRate: 0
      };
    },
    methods: {
      async fetchRates() {
        try {
          const usdtResponse = await axios.get('https://api.coincap.io/v2/rates/tether');
          const btcResponse = await axios.get('https://api.coincap.io/v2/rates/bitcoin');
          const usdtRateUsd = parseFloat(usdtResponse.data.data.rateUsd);
          const btcRateUsd = parseFloat(btcResponse.data.data.rateUsd);
          this.usdtTobtcRate = usdtRateUsd / btcRateUsd;
          this.btcToUsdtRate = btcRateUsd / usdtRateUsd;
        } catch (error) {
          console.error('Error fetching rates:', error);
        }
      },
      convertTobtc() {
        this.btcAmount = (this.usdtAmount * this.usdtTobtcRate).toFixed(6);
      },
      convertToUsdt() {
        this.usdtAmount = (this.btcAmount * this.btcToUsdtRate).toFixed(2);
      }
    },
    mounted() {
      this.fetchRates();
    }
  };
  </script>
  
  <style scoped>
  /* Ajoutez vos styles ici */
  </style>
  