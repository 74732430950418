<template>
  <div id="app">
      <div class="flex flex-col flex-1">
          <div class="d-flex align-items-center mb-10">
              <div class="rounded-circle bg-primary ma-20" style="margin-left:3%">
                  <router-link to="/"><i class="fas fa-arrow-left p-2 text-white"></i></router-link>
              </div>
              <div class="flex-grow-1 text-center"></div>
          </div>
          <div class="container-fluid">
              <div class="row align-items-center">
                  <div class="col-8 text-start">
                      <h5>Sending encrypted currency immediately</h5>
                      <p style="font-size: 12px;">Select a wallet to send encrypted currency</p>
                  </div>
                  <div class="col-4">
                      <img src="../assets/banner_account.062eb968.png" class="img-fluid" alt="" >
                  </div>
              </div>
              <div class="row align-items-end" >
                  <div class="col-3 text-end me-0 pe-0" >
                      <p class="m-0 p-0 w-100"  style="font-size: 12px; text-align: right;">Select wallet</p>
                  </div>
                  <div class="col-9 ms-0 ps-0" >
                      <div class="w-100 mt-auto border-bottom" >
                      </div>
                  </div>
              </div>
              
              <div class="text-decoration-none text-reset" v-for="row in wal" :key="row.id_compte">
                  <div class="row py-3 align-items-center justify-content-center border-bottom" v-on:click="getexchange(row)" >
                      <div class="col-7" >
                          <div class="row align-items-center justify-content-center" >
                              <div class="col-3 ml" >
                                  <img :src="'https://huobicfg.s3.amazonaws.com/currency_icon/'+ getlower(row.name_compte) +'.png'" class="img" >

                              </div>
                              <div class="col-9 text-start" >
                                  <h6 class="m-0 p-0 mb-2" >{{row.name_compte=="USDT" ? 'Theter' : 'Bitcoin'}} Wallet</h6>
                                  <p class="m-0 p-0"  style="font-size: 12px;">{{row.name_compte}} Coin</p>
                              </div>
                          </div>
                      </div>
                      <div class="col-5 text-end" >
                          <h6 class="m-0 p-0 mb-2" >US$ {{row.balance}}</h6>
                          <p class="m-0 p-0"  style="font-size: 12px;">{{row.balance}} {{ row.name_compte }}</p>
                      </div>
                  </div>
              </div>
              <div class="text-decoration-none text-reset" >
                  <div class="row py-3 align-items-center justify-content-center border-bottom" >
                      <div class="col-7" >
                          <div class="row align-items-center justify-content-center" >
                              <div class="col-3 ml" >
                                  <img src="https://huobicfg.s3.amazonaws.com/currency_icon/eth.png" class="img" >
                              </div>
                              <div class="col-9 text-start" >
                                  <h6 class="m-0 p-0 mb-2 text-start" >Ethereum Wallet</h6>
                                  <p class="m-0 p-0"  style="font-size: 12px;">ETH Coin</p>
                              </div>
                          </div>
                      </div>
                      <div class="col-5 text-end" >
                          <h6 class="m-0 p-0 mb-2" >US$ 0.00</h6>
                          <p class="m-0 p-0"  style="font-size: 12px;">0.00 ETH</p>
                      </div>
                  </div>
              </div>
              <div class="text-decoration-none text-reset" >
                  <div class="row py-3 align-items-center justify-content-center border-bottom" >
                      <div class="col-7" >
                          <div class="row align-items-center justify-content-center" >
                              <div class="col-3 ml" >
                                  <img src="https://huobicfg.s3.amazonaws.com/currency_icon/usd.png" class="img" >
                              </div>
                              <div class="col-9 text-start" >
                                  <h6 class="m-0 p-0 mb-2 text-start" >USD Wallet</h6>
                                  <p class="m-0 p-0"  style="font-size: 12px;">USD Coin</p>
                              </div>
                          </div>
                      </div>
                      <div class="col-5 text-end" >
                          <h6 class="m-0 p-0 mb-2" >US$ 0.00</h6>
                          <p class="m-0 p-0"  style="font-size: 12px;">0.00 USD</p>
                      </div>
                  </div>
              </div>
              <div class="text-decoration-none text-reset" >
                  <div class="row py-3 align-items-center justify-content-center border-bottom" >
                      <div class="col-7" >
                          <div class="row align-items-center justify-content-center" >
                              <div class="col-3 ml" >
                                  <img src="https://huobicfg.s3.amazonaws.com/currency_icon/gbp.png" class="img" >
                              </div>
                              <div class="col-9 text-start" >
                                  <h6 class="m-0 p-0 mb-2 text-start" >GBP Wallet</h6>
                                  <p class="m-0 p-0"  style="font-size: 12px;">GBP Coin</p>
                              </div>
                          </div>
                      </div>
                      <div class="col-5 text-end" >
                          <h6 class="m-0 p-0 mb-2" >US$ 0.00</h6>
                          <p class="m-0 p-0"  style="font-size: 12px;">0.00 GBP</p>
                      </div>
                  </div>
              </div>
              <div class="text-decoration-none text-reset" >
                  <div class="row py-3 align-items-center justify-content-center border-bottom" >
                      <div class="col-7" >
                          <div class="row align-items-center justify-content-center" >
                              <div class="col-3 ml" >
                                  <img src="https://huobicfg.s3.amazonaws.com/currency_icon/eur.png" class="img" >
                              </div>
                              <div class="col-9 text-start" >
                                  <h6 class="m-0 p-0 mb-2 text-start" >EUR Wallet</h6>
                                  <p class="m-0 p-0"  style="font-size: 12px;">EUR Coin</p>
                              </div>
                          </div>
                      </div>
                      <div class="col-5 text-end" >
                          <h6 class="m-0 p-0 mb-2" >US$ 0.00</h6>
                          <p class="m-0 p-0"  style="font-size: 12px;">0.00 EUR</p>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>


<script>
import axios from 'axios';
export default {
  name: 'WithdrowComponent',
  data(){
    return{
        wal:[],
        user :""
    }
  },
  created(){
      this.user = JSON.parse(localStorage.getItem('user')) ?? this.$router.push('/signin')
    },
    methods:{
        getlower(obj){
            return obj.toLowerCase();
        },
        async getwal(){
            let self = this;
            let uid = self.user.id;
            //console.log(uid)
            //let form = new FormData();
            //form.append("id",parseInt(uid));
            await axios.get("https://server.captalcoin.com/server.php?list_currency=wal&id="+uid)
            .then(function(response){
                self.wal = response.data.wal;
               // console.log(self.wal)
            })
            .catch(err=>{
                console.error(err)
            })
        },
        getexchange(obj){
            localStorage.setItem('wal',JSON.stringify(obj));
            this.$router.push('/exchange')
        }
    },
    mounted(){
        this.getwal();
    }
}

</script>

<style scoped>
#app {
  width: 100%;
  height: fit-content;
  min-height: 100vh;
  margin: 0 auto;
  display: block;
}

.img{
  width: 35px;
  height: 35px;
}
</style>
