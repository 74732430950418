<template>
    <div class="row">
      <Chart
        :options="{
          theme: 'dark',
          symbol: data,
          width:'80%',
          height: '25em'
        }"
      />
      </div>
  </template>
  
  <script>
  import { Chart } from "vue-tradingview-widgets";
  
  export default {
    name: "ChartComponent",
    components: {
      Chart
    },
    data(){
        return {
            data:''
        }
    },
    created(){
      this.data = JSON.parse(localStorage.getItem('crypto_symbol')) ? JSON.parse(localStorage.getItem('crypto_symbol'))+"USDT" : this.$router.push('/')
    }
  };
  </script>