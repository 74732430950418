<template>
    <nav class="navbar bg-body-tertiary">
        <div class="container-fluid">
            <a class="navbar-brand"><img src="../assets/logo.png" style="width:150px"></a>
            <form class="d-flex" role="search">
            
            </form>
        </div>
    </nav>
    <h1>Captalcoin</h1>

    <div class="container">
        <div class="row g-3">
            <div class="col-12"><br/><br/>
                <label class="visually-hidden" for="yourEmail">your Email</label>
                <div class="input-group">
                    <div class="input-group-text"><i class="fas fa-envelope"></i></div>
                    <input type="text" v-model="email" class="form-control" id="yourEmail" placeholder="your Email">
                </div>
            </div>
            <div class="col-12">
                <label class="visually-hidden" for="password">Enter password</label>
                <div class="input-group">
                    <div class="input-group-text"><i class="fas fa-lock"></i></div>
                    <input type="password" v-model="password" class="form-control" id="password" placeholder="Enter password">
                </div>
            </div>
            <button v-on:click="login" class="btn btn-primary btn-block col-12">Login </button>
            <router-link to="/SignUp" class="btn btn-outline-primary btn-block col-12">Register in now</router-link>
            
        </div>
        <div><router-link to="/"><i class="fas fa-home" style="font-size:30px;float:right;margin-top:50px"></i></router-link></div>
    </div>
</template>

<script>
import axios from "axios"
import swal from 'sweetalert';
export default {
    name:'SigninComponent',
    data(){
        return{
            email : "",
            password:""
        }
    },
    methods:{
        showAlert(title, msg, icon, showConfirmButton = true) {
            return swal({
                title: title,
                text: msg,
                icon: icon,
                button: showConfirmButton,
                timer: 2000,
            });
        }
        ,
        async login() {
            try {
                /*
                const response = await axios.post('https://server.captalcoin.com/user.php?reg=c_sgn', {
                email: this.email,
                password: this.password
                });
                const user = response.data.user;*/
                

                var checkuser =this.addAll();
                let self = this;
                self.showAlert("Please", "Waiting...", "warning", false)
                await axios.post("https://server.captalcoin.com/user.php?reg=c_sign",checkuser,{
                Headers:{
                    'Content-Type' : 'Multipart/form-data'
                }
                })
                .then(function(response){
                    
                    if(response.data.success==true){
                        //console.log(response)
                        setTimeout(() => {
                            localStorage.setItem('user',JSON.stringify(response.data.user));
                           self.showAlert("success","Connected successfully","success",false)
                            self.$router.push('/') 
                        }, 2000);
                        
                    }
                    else{
                        self.showAlert("Error",response.data.message,"warning")
                    }
                })
            } catch (error) {
                console.error('Erreur de connexion :', error);
            }
        },
        addAll: function () {
          var form_data = new FormData();
            form_data.append('email', this.email);
            form_data.append('password',this.password)
          return form_data;
        },

    }
}
</script>