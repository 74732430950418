import { createApp } from 'vue';
import App from './App.vue';

import 'bootstrap/dist/css/bootstrap.css';
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.js';
import router from './routers';
import '@fortawesome/fontawesome-free/css/all.css';

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

const app = createApp(App);

// Installer les plugins individuellement
app.use(VueSweetalert2);
app.use(router);
app.use(bootstrap);

// Monter l'application
app.mount('#app');
