<template>
  <div id="app" class="bg-white">
    <div class="container-fluid ">
      <div class="shadow-sm rounded d-flex justify-content-between p-3" style="background: rgb(245, 245, 245);">
        <router-link to="/">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-left"
            viewBox="0 0 16 16">
            <path fill-rule="evenodd"
              d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0" />
          </svg>
        </router-link>
        <div>Verification</div>
        <div></div>
      </div>
      <div class="container-fluid ">
        <div class="container  mt-5">

          <form class="row" v-on:submit.prevent="save">
            <div class="col-sm-6 offset-sm-3 shadow-lg p-4">
              <h2><strong>Identity verification Form</strong></h2>
              <div class="form-group mt-3">
                <label for="textInput" style="float: left;margin: auto;">Type :</label>
                <select v-model="card.type" name="" id="" class="form-control">
                  <option hidden>choose piece</option>
                  <option value="1">Identity card</option>
                  <option value="2">Passport</option>
                  <option value="3">Driver license</option>
                </select>
              </div>
              <div class="form-group mt-3">
                <label for="textInput" style="float: left;margin: auto;">ID :</label>
                <input v-model="card.idcard" type="text" class="form-control" id="textInput" placeholder="Enter Id">
              </div>
              <div class="col-12 mt-3">
                <label for="imageInput" style="float: left;margin: auto;">Upload card :</label>
                <input type="file" class="form-control-file" id="imageInput" v-on:change="chargefile" multiple>
              </div>
              <div class="col-12 mt-3">
                <button type="submit" class="btn btn-primary col-6">Send</button>
              </div>
            </div>
          </form>
        </div>


      </div>
    </div>
  </div>
</template>


<script>
import axios from 'axios'
import swal from 'sweetalert';
export default {
  name: 'IDComponent',
  data() {
    return {
      card: {
        type: null,
        idcard: null,
        uplcard: null
      },
      upl: [],
      user:null
    }
  },
  methods: {
    showAlert(title, msg, icon, showConfirmButton = true) {
      return swal({
        title: title,
        text: msg,
        icon: icon,
        button: showConfirmButton

      });
    },
    chargefile(event) {
      this.upl = [];

      const fil = Array.from(event.target.files);

      fil.forEach(file => {
        this.upl.push({
          file: file,
          preview: URL.createObjectURL(file)
        })
        this.card.uplcard = file;
      });
      console.log(this.upl)
    },
    async save() {
      try {
        let self = this;
        var formdata = this.addAll()
        self.showAlert("Info", "Waiting...", "warning", false)
        axios.post("https://server.captalcoin.com/user.php?reg=checkcard", formdata, {
          Headers: {
            'Content-Type': 'Multipart/form-data'
          }
        })
          .then(function (response) {
            //console.log(response)
            if (response.data.success == true) {

              setTimeout(() => {
                localStorage.setItem('user',JSON.stringify(response.data.user));
                self.showAlert("success",response.data.message, "success", false)
                self.$router.push('/')
              }, 5000);
            }
            else {
              console.log(response)
              self.showAlert("Error", response.data.message, "warning")
            }
          })
      } catch (error) {
        console.error("error :", error)
      }
    },
    addAll: function () {
      var form_data = new FormData();
      let self = this;
      if (this.card.type == null) {
        self.showAlert("Error", "Type required", "warning")
      }
      else if (this.card.idcard == null) {
        self.showAlert("Error", "IdCard required", "warning")
      }
      else if (this.card.uplcard == null) {
        self.showAlert("Error", "File required", "warning")
      }
      else if (this.user == null || this.user.id == null) {
        this.$router.push('/signin')
      }
      else {
        form_data.append('type', this.card.type);
        form_data.append('idcard', this.card.idcard)
        form_data.append('images', this.card.uplcard)
        form_data.append('id', this.user.id)
        return form_data;
      }
    },
  },
  created(){
      this.user = JSON.parse(localStorage.getItem('user')) ?? this.$router.push('/signin')
    }
}

</script>

<style scoped>
#app {
  width: 100%;
  height: fit-content;
  min-height: 100vh;
  margin: 0 auto;
  display: block;
}

input[type="file"] {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #ced4da;
  border-radius: 4px;
  background-color: #e9ecef;
}

input[type="file"] {
  padding: 5px;
}

input[type="file"]::file-selector-button {
  background-color: #517fb1;
  color: white;
  border: none;
  padding: 8px 15px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 1rem;
}

input[type="file"]::file-selector-button:hover {
  background-color: #294741;
}
</style>
