<template>
    <nav class="navbar bg-body-tertiary">
        <div class="container-fluid">
            <a class="navbar-brand"><img src="../assets/logo.png" style="width:150px"></a>
            <form class="d-flex" role="search">
            
            </form>
        </div>
    </nav>
    <h1>Captalcoin</h1>

    <div class="container">
        <div class="row g-3">
            <div class="col-12"><br/><br/>
                <label class="visually-hidden" for="yourEmail">your Email</label>
                <div class="input-group">
                    <div class="input-group-text"><i class="fas fa-envelope"></i></div>
                    <input type="email" v-model="email" class="form-control" id="yourEmail" placeholder="your Email">
                </div>
            </div>
            <div class="col-12">
                <label class="visually-hidden" for="password">Enter password</label>
                <div class="input-group">
                    <div class="input-group-text"><i class="fas fa-lock"></i></div>
                    <input type="password" v-model="password" class="form-control" id="password" placeholder="Enter password">
                </div>
            </div>
            <div class="col-12">
                <label class="visually-hidden" for="confirm_password">Please enter confirm password</label>
                <div class="input-group">
                    <div class="input-group-text"><i class="fas fa-lock"></i></div>
                    <input type="password" v-model="confirm_password" class="form-control" id="confirm_password" placeholder="Please enter confirm password">
                </div>
            </div>
            <button v-on:click="SignUp" class="btn btn-primary btn-block col-12">Register</button>
            <router-link to="/signin" class="btn btn-outline-primary btn-block col-12">Login in now</router-link>
            
        </div>
        <div><router-link to="/"><i class="fas fa-home" style="font-size:30px;float:right;margin-top:50px"></i></router-link></div>

    </div>



</template>


<script>
import axios from 'axios'
import swal from 'sweetalert';
export default {
    name : 'SignUp',
    data(){
        return {
            email:'',
            password:'',
            confirm_password:'',
        }
    },
    methods:{
        showAlert(title, msg, icon, showConfirmButton = true) {
            return swal({
                title: title,
                text: msg,
                icon: icon,
                button: showConfirmButton

            });
        },
        async SignUp(){
            try {
                /*    let result = await axios.post("https://server.captalcoin.com/user.php?reg=ad",{
                    email:this.email,
                    password:this.password
                });
                if(result.status==200){
                //  localStorage.setItem("user-info",JSON.stringify(result.data))
                    this.$router.push({name:"SigninComponent"})
                }
                console.log(result)*/

               // var newuser =this.addAll();
                let self = this;
                var form_data = new FormData();
                const regex =/^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if(this.email == ""){
                    self.showAlert("Error","Email required","warning")
                }
                else if(!regex.test(this.email)){
                    self.showAlert("Error","Email not valid","warning")
                }
                else if(this.password == "" || this.confirm_password=="" || this.password != this.confirm_password){
                    self.showAlert("Error","Password required","warning")
                }
                else{
                    form_data.append('email', this.email);
                    form_data.append('password',this.password)
                    self.showAlert("Please", "Waiting...", "warning", false)
                    axios.post("https://server.captalcoin.com/user.php?reg=ad",form_data,{
                    Headers:{
                        'Content-Type' : 'Multipart/form-data'
                    }
                    })
                    .then(function(response){
                        //console.log(response)
                        if(response.data.success==true){
                            
                            setTimeout(() => {
                            
                            self.showAlert("success","Registered successfully, check mail to validate your account","success",false)
                                self.$router.push('/') 
                            }, 5000);
                        }
                        else{
                            console.log(response)
                            self.showAlert("Error",response.data.message,"warning")
                        }
                    })
                }
            } catch (error) {
                console.error("error :",error)
            }
        },
        addAll: function () {
            var form_data = new FormData();
            if(this.password == this.confirm_password){
                form_data.append('email', this.email);
                form_data.append('password',this.password)
                return form_data;
            }
            else{
                if(this.email == ""){
                    self.showAlert("Error","Email required","warning")
                }
                else{
                    self.showAlert("Error","Invalid Password","warning")
                }

                return false
            }
            
        },
    },
    mounted(){
       // let user = localStorage.setItem('user-info');
       // if(user){
           // this.$router.push({name:"HomeComponent"})
      //  }
    }
}
</script>




