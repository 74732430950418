<template>
       
  <div id="app">
    <div class="container-fluid">
      <div class="shadow-sm rounded d-flex justify-content-between p-3">
        <router-link to="/">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-left"
            viewBox="0 0 16 16">
            <path fill-rule="evenodd"
              d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0" />
          </svg>
        </router-link>
        <div>Deposit / withdraw records</div>
        <div></div>
      </div>
      <div class="container bg-white">
        <div class="row">
          <ul class="nav nav-pills mb-3 bg-white p-2 d-flex justify-content-between px-3" id="pills-tab" role="tablist">
            <li class="nav-item" role="presentation">
              <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home"
                type="button" role="tab" aria-controls="pills-home" aria-selected="true">Deposit records</button>
            </li>
            <li class="nav-item" role="presentation">
              <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile"
                type="button" role="tab" aria-controls="pills-profile" aria-selected="false">withdraw records</button>
            </li>
          </ul>
          <div class="tab-content " id="pills-tabContent">
            <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
              <div class="col-xxl-4 mb-4">

                <div class=" mb-3  bg-opacity-10" v-for="depot in depost" :key="depot.id_deposit">
                  <div class=" d-flex justify-content-between align-items-center  ">
                    <div>{{ depot.adress }}</div>
                    <div class="text-sm">{{ depot.created_at }} </div>
                  </div>
                  <div class="  text-dark">
                    <div class="d-flex justify-content-between">
                      <div class="text-start">
                        <br>
                        <p class="fpnt-bold">Recharge Funds</p>
                        <p class="font-bold">Recharge status</p>
                      </div>
                      <div class="text-end">
                        <br>
                        <h5 class="font-bold">{{ depot.amount }} {{ depot.adress }}</h5>
                        <button :class=" depot.statut_depot == 'successful' ? 'btn btn-success' : 'btn btn-danger text-start btnd'"
                          style="background-color:#4ec752;">{{ depot.statut_depot }}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">

              <div class="col-xxl-4 mb-4">

                <div class=" mb-3  bg-opacity-10" v-for="row in withdrw" :key="row.id_withdraw">
                  <div class=" d-flex justify-content-between align-items-center  ">
                    <div>BTC/USDT</div>
                    <div class="text-sm">{{row.created_at}} </div>
                  </div>
                  <div class="  text-dark">
                    <div class="d-flex justify-content-between">
                      <div class="text-start">
                        <br>
                        <p class="fpnt-bold">Amount of Withdraws records</p>
                        <p class="font-bold">Currency withdrawal status</p>

                      </div>
                      <div class="text-end">
                        <br>
                        <h5 class="font-bold">{{row.amount}}</h5>
                        <button :class=" row.statut == 'successful' ? 'btn btn-success' : 'btn btn-danger text-start btnd'">{{row.statut}}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import axios from 'axios';
import swal from 'sweetalert';
export default {
  name: 'RecordsComponent',
  data() {
    return {
      user: null,
      sender: {
        name_adresse: "",
        adresse: "",
        id_user: ""
      },
      withdrw: [],
      depost: []
    }
  },
  created() {
    this.user = JSON.parse(localStorage.getItem('user')) ?? this.$router.push('/signin')
  },
  methods: {
    async send() {
      let self = this;
      if (this.sender.name == "" || this.sender.adresse == "" || this.user == null) {
        swal('Error', 'All field required', 'warning')
      }
      else {
        let form = new FormData();
        form.append("name_adresse", this.sender.name_adresse);
        form.append("adresse", this.sender.adresse);
        form.append("id_user", this.user.id);
        await axios.post("https://server.captalcoin.com/user.php?reg=add_adr", form, {
          headers: {
            'Content-Type': 'Multipart/form-data'
          }
        })
          .then(response => {
            if (response.data.success) {
              swal("Good job!", response.data.message, "success");
              self.$router.go(0)
            }
            else {
              swal("Error", response.data.message, "warning");
            }
          })

      }
    },
    async withdraw_fn() {
      let self = this;
      const id_user = this.user.id;
      await axios.get("https://server.captalcoin.com/user.php?reg=wdw&us=" + id_user)
        .then(response => {
          if (response.data.success) {
            self.withdrw = response.data.withdraw;
            self.depost = response.data.deposit;
          }
        })
    }
  },
  mounted() {
    this.withdraw_fn();
    ///this.deposit_fn();
    setInterval(this.withdraw_fn, 20000);
  }
}


</script>

<style>
#app {
  width: 100%;
  height: fit-content;
  min-height: 100vh;
  margin: 0 auto;
  display: block;
  background: rgb(245, 245, 245);
}

.btnd {
  font-size: 12px;
}
</style>
